import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { MatomoTracker } from 'ngx-matomo-client';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom, take } from 'rxjs';
import { DocumentService } from 'src/app/api/services/document.service';
import { PoliciesService } from 'src/app/api/services/policies.service';
import { AppResource } from 'src/app/app.resource';
import { MimeType } from 'src/app/core/enums/mime-type.enum';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { Contract, Devis, SaisieSignature } from 'src/app/core/models/form-state.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromContext from 'src/app/core/state/context';
import * as fromContract from 'src/app/core/state/contract';
import { State } from 'src/app/core/state/core.state';
import * as fromElectronicSignature from 'src/app/core/state/electronic-signature';
import * as fromInfo from 'src/app/core/state/info';
import * as fromPayment from 'src/app/core/state/payment';
import { PaymentState } from 'src/app/core/state/payment';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { VYV_BRAND } from 'src/app/shared/constants/brand.constants';
import {
  CERTIFICATE_EXPIRED,
  CERTIFICATE_PIN_INCORRECT,
  MAXIMUM_INCORRECT_CERTIFICATE_PIN_ATTEMPTS_REACHED
} from 'src/app/shared/constants/error.constants';
import { TEL } from 'src/app/shared/constants/phone-number.constants';
import { REGEX_E_SIGNATURE } from 'src/app/shared/constants/regex.constants';
import { getMetropolePhoneNumber } from 'src/app/shared/helpers/metropole-helper.service';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { InfoTopVM } from 'src/app/shared/models/components/infotopVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { SignatureVM } from 'src/app/shared/models/components/signatureVm';
import { ElectronicSignatureEnum } from 'src/app/shared/models/enum/electronicsignature.enum';
import { PolicySummaryService } from 'src/app/shared/services/policy-summary.service';
import { ApiRequestBodyUtils } from 'src/app/shared/utils/apiRequestBodyUtils';
import { isMobile } from 'src/app/shared/utils/deviceUtils';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import * as fromDevis from '../../../core/state/devis';
import * as fromPath from '../../../core/state/path';
import * as fromSubscriber from '../../../core/state/subscriber';
import { SubscriberState } from '../../../core/state/subscriber';

@Component({
  selector: 'app-electronic-signature',
  templateUrl: './electronic-signature.component.html',
  styleUrls: ['./electronic-signature.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ElectronicSignatureComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  infoTop!: InfoTopVM;
  override question!: QuestionVM;
  stepB: boolean = false;

  electronicSignatureEnum = ElectronicSignatureEnum;
  signatureVm!: SignatureVM;

  condPCheck: boolean = false;
  infoCheck: boolean = false;
  sepaCheck: boolean = false;

  isChecked: boolean = false;

  context!: string;
  contrat!: Contract;
  devis!: Devis;
  subscriber!: SubscriberState;
  paymentData!: PaymentState;
  path!: PathType;
  sansEffetPhoneNumber: string = '';

  //Style
  condP: boolean = true;
  infoB: boolean = false;
  sepa: boolean = false;
  numericCodeForm!: FormGroup;

  isLoading: boolean = false;
  displayError: boolean = false;
  displayErrorText: boolean = false;
  displayContextErrorModal: boolean = false;

  displayModal: boolean = false;
  titleModal!: string;
  descriptionModal!: string;

  errorCodeSMS: string | undefined;
  errorBadCodeSMS: boolean = false;
  showCantSignModal: boolean = false;

  get NOne() {
    return this.numericCodeForm.get('NOne');
  }

  get NTwo() {
    return this.numericCodeForm.get('NTwo');
  }

  get NThree() {
    return this.numericCodeForm.get('NThree');
  }

  get NFour() {
    return this.numericCodeForm.get('NFour');
  }

  get NFive() {
    return this.numericCodeForm.get('NFive');
  }

  get NSix() {
    return this.numericCodeForm.get('NSix');
  }

  get brand() {
    return environment.brand;
  }

  constructor(
    private spinner: NgxSpinnerService,
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private elementRef: ElementRef,
    private policyService: PoliciesService,
    private documentService: DocumentService,
    private policySummaryService: PolicySummaryService
  ) {
    super(store, resources, tracker);

    this.headerStep = new HeaderVM(
      this.brand === 'HM' ? this.resource.header.stepContract : this.resource.header.stepSignature,
      3,
      this.resource.header.subStepSignature,
      9
    );
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.spinner.show();
    this.initForm();
    await this.loadStateData();
    if (this.context) {
      this.initVm();

      this.getSansEffetPhoneNumber();
    } else {
      this.displayContextErrorModal = true;
    }
  }

  private initForm() {
    this.numericCodeForm = new FormGroup({
      NOne: new FormControl<number | null>(null, {
        validators: [Validators.required, Validators.maxLength(1), Validators.pattern('^[0-9]*$')],
        nonNullable: true
      }),
      NTwo: new FormControl<number | null>(null, {
        validators: [Validators.required, Validators.maxLength(1), Validators.pattern('^[0-9]*$')],
        nonNullable: true
      }),
      NThree: new FormControl<number | null>(null, {
        validators: [Validators.required, Validators.maxLength(1), Validators.pattern('^[0-9]*$')],
        nonNullable: true
      }),
      NFour: new FormControl<number | null>(null, {
        validators: [Validators.required, Validators.maxLength(1), Validators.pattern('^[0-9]*$')],
        nonNullable: true
      }),
      NFive: new FormControl<number | null>(null, {
        validators: [Validators.required, Validators.maxLength(1), Validators.pattern('^[0-9]*$')],
        nonNullable: true
      }),
      NSix: new FormControl<number | null>(null, {
        validators: [Validators.required, Validators.maxLength(1), Validators.pattern('^[0-9]*$')],
        nonNullable: true
      })
    });
  }

  private async loadStateData() {
    // Retrieve context
    let contextId = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );
    this.context = contextId!;

    // Retrieve contract
    this.contrat = await lastValueFrom(
      this.store.select(fromContract.selectContractState).pipe(take(1))
    );

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    this.paymentData = await lastValueFrom(
      this.store.select(fromPayment.selectPayment).pipe(take(1))
    );

    this.store.select(fromElectronicSignature.selectElectronicSignature).subscribe(res => {
      this.condPCheck = res.electronicSignature.AcceptationCP;
      this.infoCheck = res.electronicSignature.AcceptationFIC;
      this.sepaCheck = res.electronicSignature.AcceptationMandatSEPA;
    });

    this.devis = await lastValueFrom(this.store.select(fromDevis.selectDevisState).pipe(take(1)));

    this.subscriber = await lastValueFrom(
      this.store.select(fromSubscriber.selectSubscriber).pipe(take(1))
    );
  }

  private async initVm() {
    if (this.contrat) {
      this.signatureVm = new SignatureVM(
        this.contrat.RefDocumentCP,
        this.contrat.RefDocumentFIC,
        this.contrat.RefDocumentMandatSEPA
      );
      if (this.contrat.NumeroContrat) {
        this.policyService
          .getPolicyDocument(Guid.parse(this.contrat.NumeroContrat)['value'])
          .subscribe(async res => {
            await this.getCPDocument(res.cpFileId);
            await this.getFICDocument(res.ficFileId);
            await this.getMandatSepaDocument(res.sepaFileId);
          });
      }
    } else {
      this.signatureVm = new SignatureVM('', '', '');
    }

    const paymentPrefixTel =
      this.paymentData.payment.PaymentPrefixTel != null
        ? this.paymentData.payment.PaymentPrefixTel
        : this.paymentData.payment.PrefixeTel;
    const paymentTelephone =
      this.paymentData.payment.PaymentTelephone != null
        ? this.paymentData.payment.PaymentTelephone
        : this.paymentData.payment.Telephone;

    this.infoTop = new InfoTopVM(
      this.resource.infotop.codeConfirmation,
      this.resource.infotop.codeConfirmationS,
      paymentPrefixTel + paymentTelephone,
      this.resource.infotop.logoMessage,
      true
    );

    this.question = new QuestionVM(this.resource.question.signature1);
  }

  updateChecks() {
    this.condPCheck = this.isChecked;
    this.infoCheck = this.isChecked;
    this.sepaCheck = this.isChecked;
  }

  getSansEffetPhoneNumber() {
    if (this.path === PathType.HOUSING) {
      this.sansEffetPhoneNumber = this.resource.signature.phoneNumberHousing;
    } else if (this.path === PathType.HOUSING_SOLIDARITY) {
      this.sansEffetPhoneNumber = this.resource.signature.phoneNumberHousingSolidarity;
    } else {
      const phoneNumber = getMetropolePhoneNumber(this.path);
      if (phoneNumber) {
        this.sansEffetPhoneNumber = String.format(
          this.resource.signature.phoneNumberHousingMetropole,
          phoneNumber
        );
      }
    }
  }

  async validateStepOne() {
    this.isLoading = true;

    const saisieSignature: SaisieSignature = {
      AcceptationCP: this.condPCheck,
      AcceptationFIC: this.infoCheck,
      AcceptationMandatSEPA: this.sepaCheck
    };

    this.store.dispatch(
      fromElectronicSignature.patchSignature({
        payload: saisieSignature
      })
    );

    const paymentPrefixTel =
      this.paymentData.payment.PaymentPrefixTel != null
        ? this.paymentData.payment.PaymentPrefixTel
        : this.paymentData.payment.PrefixeTel;
    const paymentTelephone =
      this.paymentData.payment.PaymentTelephone != null
        ? this.paymentData.payment.PaymentTelephone
        : this.paymentData.payment.Telephone;

    this.policyService
      .startSignature(
        Guid.parse(this.contrat.NumeroContrat)['value'],
        paymentPrefixTel,
        paymentTelephone
      )
      .subscribe({
        next: (res: any) => {
          this.store.dispatch(
            fromElectronicSignature.patchSignature({
              payload: {
                SignatureToken: res['signatureProcessNumber']
              }
            })
          );
          this.question = new QuestionVM(this.resource.question.signature2);
          this.stepB = true;
        },
        error: () => {
          this.isLoading = false;
          this.displayError = true;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  async sendBackSmsCode() {
    const signatureState = await lastValueFrom(
      this.store.select(fromElectronicSignature.selectElectronicSignature).pipe(take(1))
    );
    this.isLoading = true;
    this.policyService
      .resendSmsPinCode(
        Guid.parse(this.contrat.NumeroContrat)['value'],
        signatureState.electronicSignature.SignatureToken ?? ''
      )
      .subscribe({
        next: (res: any) => {},
        error: () => {
          this.isLoading = false;
          this.displayError = true;
        },
        complete: () => {
          this.isLoading = false;
        }
      });
  }

  async validateStepTwo() {
    this.isLoading = true;

    const codeSms: string =
      this.NOne?.value +
      this.NTwo?.value +
      this.NThree?.value +
      this.NFour?.value +
      this.NFive?.value +
      this.NSix?.value;

    this.store.dispatch(
      fromElectronicSignature.patchSignature({
        payload: {
          CodeSms: codeSms
        }
      })
    );

    this.store.dispatch(
      fromElectronicSignature.changeValidationStatus({
        isValid: true
      })
    );

    // Retrieve signature state
    const signatureState = await lastValueFrom(
      this.store.select(fromElectronicSignature.selectElectronicSignature).pipe(take(1))
    );

    // Validate signature
    const bodySignDocuments = ApiRequestBodyUtils.GenerateSignatureSignRequestBody(
      this.context,
      this.contrat.NumeroContrat,
      signatureState.electronicSignature
    );

    console.log('bodySignDocuments', bodySignDocuments);

    this.policyService
      .completeSignature(
        Guid.parse(this.contrat.NumeroContrat)['value'],
        bodySignDocuments.TokenSignature,
        bodySignDocuments.CodeSMS
      )
      .subscribe({
        next: () => {
          this.policySummaryService.processPolicySummary(
            this.contrat.NumeroContrat,
            this.context,
            this.subscriber.subscriber?.Email!
          );
        },
        error: resSignature => {
          this.isLoading = false;

          const errorDetail = resSignature.error?.detail;
          const errorCodeMatch = errorDetail?.match(REGEX_E_SIGNATURE);
          const errorCode = errorCodeMatch ? errorCodeMatch[1] : null;

          if (errorCode === CERTIFICATE_PIN_INCORRECT) {
            this.errorCodeSMS = this.resource.error.certificatePinIncorrect;
            this.displayErrorText = true;
          } else if (errorCode === CERTIFICATE_EXPIRED) {
            this.errorCodeSMS = this.resource.error.certificatePinExpired;
            this.displayErrorText = true;
          } else if (errorCode === MAXIMUM_INCORRECT_CERTIFICATE_PIN_ATTEMPTS_REACHED) {
            this.errorCodeSMS = this.resource.error.smsCodeAttemptsExceeded;
            this.displayErrorText = true;
          } else {
            this.displayError = true;
          }

          this.NOne?.setValue('');
          this.NTwo?.setValue('');
          this.NThree?.setValue('');
          this.NFour?.setValue('');
          this.NFive?.setValue('');
          this.NSix?.setValue('');
        },
        complete: () => {
          this.isLoading = false;
          this.displayError = false;
          this.displayErrorText = false;
          this.updateStepper();
        }
      });
  }

  updateClickButton(value: string) {
    switch (value) {
      case ElectronicSignatureEnum.CondP:
        if (!this.condP) {
          this.condP = !this.condP;
          this.infoB = false;
          this.sepa = false;
        }
        break;
      case ElectronicSignatureEnum.Info:
        if (!this.info) {
          this.condP = false;
          this.infoB = !this.info;
          this.sepa = false;
        }
        break;
      case ElectronicSignatureEnum.Sepa:
        if (!this.sepa) {
          this.condP = false;
          this.infoB = false;
          this.sepa = !this.sepa;
        }
        break;
    }
  }

  onKeyUp(event: any, nextValue: string) {
    const input = event.target;
    if (isNaN(input.value)) {
      input.value = '';
      return;
    }
    var nbVal = parseInt(input.value);
    if (nbVal < 0 || nbVal > 9) {
      input.value = '';
      return;
    }
    this.elementRef.nativeElement.querySelector('#' + nextValue).focus();
  }

  onProgress(progressData: any, documentType: ElectronicSignatureEnum) {
    this.spinner.show();
    if (progressData.loaded == progressData.total) {
      this.spinner.hide();
      switch (documentType) {
        case ElectronicSignatureEnum.CondP:
          this.signatureVm.condPLoaded = true;
          break;
        case ElectronicSignatureEnum.Info:
          this.signatureVm.infoLoaded = true;
          break;
        case ElectronicSignatureEnum.Sepa:
          this.signatureVm.sepaLoaded = true;
          break;
      }
    }
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.signature
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.electronicsignature
    );

    if (subtitleS) {
      subtitleS.isValid = true;
      subtitleS.isActive = true;
    }

    let subtitleNS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.confirmcontract
    );

    if (subtitleNS) {
      subtitleNS.isValid = true;
      subtitleNS.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  confirmModal() {
    this.displayModal = false;
    this.showCantSignModal = false;
  }

  getClass(value: boolean) {
    return value ? 'active-ES' : 'inactive-ES';
  }

  cantTriggerSignature(): boolean {
    return (
      !this.signatureVm.condPLoaded || !this.signatureVm.infoLoaded || !this.signatureVm.sepaLoaded
    );
  }

  onCpPdfError(error: any): void {
    console.error(error);
    this.signatureVm.condPLoaded = false;
    this.signatureVm.condPUrlEmbed = '';
    this.spinner.hide();
  }

  onFicPdfError(error: any): void {
    console.log(error);
    this.signatureVm.infoLoaded = false;
    this.signatureVm.infoUrlEmbed = '';
    this.spinner.hide();
  }

  onMandatSepaPdfError(error: any): void {
    console.log(error);
    this.signatureVm.sepaLoaded = false;
    this.signatureVm.sepaUrlEmbed = '';
    this.spinner.hide();
  }

  callClientService(): void {
    if (isMobile()) {
      let supportPhoneNumber =
        this.brand === VYV_BRAND
          ? this.sansEffetPhoneNumber.replace(/\s/g, '')
          : this.resource.signature.clientServicePhoneNumber;
      window.location.href = TEL + `${supportPhoneNumber}`;
    }
  }

  async getCPDocument(ref: any) {
    const data = await this.documentService
      .getAgreementDocument(ref, this.devis.AgreementId!, this.subscriber.subscriber?.Email!)
      .toPromise();

    const blob = this.documentService.convertBase64ToBlob(
      atob(data!.fileContents),
      MimeType.OCTET_STREAM
    );
    const url = window.URL.createObjectURL(blob);
    const blobView = this.documentService.convertBase64ToBlob(
      atob(data!.fileContents),
      MimeType.APPLICATION_PDF
    );
    const urlView = URL.createObjectURL(blobView);
    this.signatureVm.condPUrlEmbed = url;
    this.signatureVm.condPUrlDl = urlView;
  }

  async getFICDocument(ref: any) {
    const data = await this.documentService
      .getAgreementDocument(ref, this.devis.AgreementId!, this.subscriber.subscriber?.Email!)
      .toPromise();
    const blob = this.documentService.convertBase64ToBlob(
      atob(data!.fileContents),
      MimeType.OCTET_STREAM
    );
    const url = window.URL.createObjectURL(blob);
    const blobView = this.documentService.convertBase64ToBlob(
      atob(data!.fileContents),
      MimeType.APPLICATION_PDF
    );
    const urlView = URL.createObjectURL(blobView);
    this.signatureVm.infoUrlEmbed = url;
    this.signatureVm.infoUrlDl = urlView;
  }

  async getMandatSepaDocument(ref: any) {
    const data = await this.documentService
      .getAgreementDocument(ref, this.devis.AgreementId!, this.subscriber.subscriber?.Email!)
      .toPromise();
    const blob = this.documentService.convertBase64ToBlob(
      atob(data!.fileContents),
      MimeType.OCTET_STREAM
    );
    const url = window.URL.createObjectURL(blob);
    const blobView = this.documentService.convertBase64ToBlob(
      atob(data!.fileContents),
      MimeType.APPLICATION_PDF
    );
    const urlView = URL.createObjectURL(blobView);
    this.signatureVm.sepaUrlEmbed = url;
    this.signatureVm.sepaUrlDl = urlView;
  }

  // Method to close the error dialog
  closeErrorModal() {
    this.displayContextErrorModal = false;
  }
}

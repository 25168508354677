import { inject, Injectable } from '@angular/core';
import { WINDOW, WindowRef } from './window.service';

export interface BaremeValeurAssureeElement {
  Id: number;
  NombrePieces: number;
  F1: number;
  F2: number;
}

export type BaremeValeurAssuree = BaremeValeurAssureeElement[];

export type Countries = string[];

export interface BailleurElement {
  Id: number;
  Email: string;
  Name: string;
}

export type Bailleurs = BailleurElement[];
export type Assureurs = AssureurElement[];

export interface AssureurElement {
  Id: number;
  NomAssureur: string;
  AdressePostale: string;
  CodePostal: string;
  Ville: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private windowRef = inject<WindowRef>(WINDOW);
  constructor() {}
  get baremeValeurAssuree(): BaremeValeurAssuree {
    return (this.windowRef as any).vyvBaremeValeurAssuree;
  }
}

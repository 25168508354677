<app-header [headerStep]="headerStep" [local]="true"></app-header>
<div class="mb-6">
  <div class="md:container md:mx-auto">
    <div *ngIf="iB_CP" class="container-OC flex">
      <div class="panelCPlusLogo-OC mx-5 my-auto flex-grow"></div>
      <div class="mr-5 my-5 text-right flex-1">
        <p class="rate-OC">{{ schoolInsuranceVm.monthlyRate }}</p>
        <p class="textMonth-OC">{{ resource.offercustomization.textMonth }}</p>
      </div>
    </div>

    <div *ngIf="iB_C" class="containerC-OC flex">
      <div class="panelCLogo-OC mx-5 my-auto flex-grow"></div>
      <div class="mr-5 my-5 text-right flex-1">
        <p class="rateC-OC">{{ schoolInsuranceVm.monthlyRate }}</p>
        <p class="textMonthC-OC">{{ resource.offercustomization.textMonth }}</p>
      </div>
    </div>

    <div *ngIf="iB_P" class="container-OC flex">
      <div [ngClass]="productClass" class="mx-5 my-auto flex-grow"></div>
      <div class="mr-5 my-5 text-right flex-1">
        <p class="rate-OC">{{ schoolInsuranceVm.monthlyRate }}</p>
        <p class="textMonth-OC">{{ resource.offercustomization.textMonth }}</p>
      </div>
    </div>

    <div *ngIf="iB_S" class="container-OC flex">
      <div class="panelSLogo-OC mx-5 my-auto flex-grow"></div>
      <div class="mr-5 my-5 text-right flex-1">
        <p class="rate-OC">{{ schoolInsuranceVm.monthlyRate }}</p>
        <p class="textMonth-OC">{{ resource.offercustomization.textMonth }}</p>
      </div>
    </div>

    <div
      class="containerOption px-5 md:px-24 pt-8 pb-7 space-y-3"
      [ngStyle]="{ 'min-height': iB_CP ? '129px' : '89px' }">
      <div class="flex" *ngFor="let option of options">
        <p class="flex-3 textOption">{{ option.name }}</p>
        <p class="flex-1 textBOption text-right">{{ option.valueShow }}</p>
        <p class="pl-3" *ngIf="option.optional">
          <span class="trash"></span>
        </p>
      </div>
    </div>
  </div>

  <div class="container mx-auto">
    <app-question [question]="question" [local]="true"></app-question>

    <div class="container pb-2">
      <form [formGroup]="form" *ngIf="form">
        <div
          *ngFor="let control of childrensFieldAsFormArray.controls; let i = index"
          formArrayName="childrens">
          <div [formGroupName]="i">
            <div class="mt-4">
              <div
                *ngIf="form.value.childrens[i].enable"
                class="containerForm-SI mx-auto py-5 px-5 space-y-4">
                <div class="flex">
                  <p class="title-SI grow">{{ getLibelle(i) }}</p>
                  <button
                    *ngIf="i !== 0"
                    class="button flex-none w-18"
                    type="button"
                    (click)="removeChildren(i)">
                    <img [src]="resource.schoolinsurance.removePath" class="text-right" />
                  </button>
                </div>

                <span class="p-float-label flex">
                  <input
                    type="text"
                    pInputText
                    formControlName="lastname"
                    (keyup)="ToCapitalize($event)" />
                  <label for="float-input" class="inputtext">{{ resource.form.lastName }}</label>
                </span>

                <span class="p-float-label flex">
                  <input
                    type="text"
                    pInputText
                    formControlName="firstname"
                    (keyup)="ToCapitalize($event)" />
                  <label for="float-input" class="inputtext">{{ resource.form.firstName }}</label>
                </span>

                <span class="p-float-label flex">
                  <p-calendar
                    [touchUI]="true"
                    class="w-screen"
                    dataType="string"
                    dateFormat="dd/mm/yy"
                    [maxDate]="maxDate"
                    formControlName="birthday"></p-calendar>
                  <label for="float-input" class="calendar">{{ resource.form.birthday }}</label>
                  <img [src]="resource.payment.logo" class="calendarSrc" />
                </span>

                <button
                  pButton
                  class="mx-auto my-4 button-outline-call-SV width-max iconSideLabel"
                  [label]="resource.nextstep.validate"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  (click)="validate(i)"
                  [disabled]="form.invalid"></button>
              </div>
              <div
                *ngIf="!form.value.childrens[i].enable"
                class="containerForm-SI mx-auto py-5 px-5">
                <div class="flex space-x-3">
                  <p *ngIf="i === 0" class="title-SI grow">Votre {{ i + 1 }}er enfant</p>
                  <p *ngIf="i !== 0" class="title-SI grow">Votre {{ i + 1 }}ème enfant</p>
                  <button class="button flex-none w-18" type="button" (click)="editChildren(i)">
                    <img [src]="resource.schoolinsurance.editPath" class="text-right" />
                  </button>
                  <button
                    *ngIf="i !== 0"
                    class="button flex-none w-18"
                    type="button"
                    (click)="removeChildren(i)">
                    <img [src]="resource.schoolinsurance.removePath" class="text-right" />
                  </button>
                </div>
                <div class="flex pt-4">
                  <div class="w-1/3 space-y-1">
                    <div class="textHeadColumn">
                      <p>{{ resource.form.lastName }}</p>
                    </div>
                    <div class="textColumn">
                      <p style="overflow-wrap: break-word">
                        {{ form.value.childrens[i].lastname }}
                      </p>
                    </div>
                  </div>
                  <div class="w-1/3 space-y-1">
                    <div class="textHeadColumn">
                      {{ resource.form.firstName }}
                    </div>
                    <div class="textColumn">
                      <p style="overflow-wrap: break-word">
                        {{ form.value.childrens[i].firstname }}
                      </p>
                    </div>
                  </div>
                  <div class="w-1/3 space-y-1">
                    <div class="textHeadColumn" for="birthdate">
                      {{ resource.form.birthday }}
                    </div>
                    <div class="textColumn" for="birthdate">
                      {{ form.value.childrens[i].birthday }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="addNC && form.value.childrens.length < 6" class="container mx-5 my-5">
          <button class="button text-SI flex" (click)="addChildren()">
            <img [src]="resource.schoolinsurance.addPath" />
            <span class="px-2 py-1">{{ resource.schoolinsurance.add }}</span>
          </button>
        </div>
        <div class="mx-5 mt-6 mb-4" *ngIf="addNC">
          <button
            pButton
            class="mx-auto my-4 button-NS width-max iconSideLabel"
            [label]="resource.nextstep.nextStep"
            icon="pi pi-arrow-right"
            iconPos="right"
            (click)="nextStep()"
            [disabled]="form.invalid"
            [loading]="isLoading"></button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="container mx-auto h-28 md:h-40">
  <app-footer class="footer"></app-footer>
</div>
<app-web-callback></app-web-callback>

<app-error-popup
  [show]="displayError"
  (cancel)="displayError = false"
  [title]="resource.error.oups"
  [text]="resource.error.api"></app-error-popup>

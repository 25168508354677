// src/app/core/state/menus/menus.reducer.ts

import { Action, createReducer, on } from '@ngrx/store';
import * as AddressActions from './address.actions';
import { AddressState, initialState } from './address.state';

const addressReducer = createReducer(
  initialState,
  on(
    AddressActions.patchManuelle,
    (state, action: ReturnType<typeof AddressActions.patchManuelle>) => ({
      ...state,
      addresseManuelle: { ...state.addresseManuelle, ...action.payload }
    })
  ),
  on(
    AddressActions.patchSelection,
    (state, action: ReturnType<typeof AddressActions.patchSelection>) => ({
      ...state,
      addresseSelection: { ...state.addresseSelection!, ...action.payload }
    })
  ),
  on(AddressActions.patchEsh, (state, action: ReturnType<typeof AddressActions.patchEsh>) => ({
    ...state,
    adresseEsh: { ...state.adresseEsh!, ...action.payload }
  })),
  on(
    AddressActions.patchSelectedAddress,
    (state, { adresseType }: ReturnType<typeof AddressActions.patchSelectedAddress>) => ({
      ...state,
      adresseType
    })
  ),
  on(
    AddressActions.changeValidationStatus,
    (state, { isValid }: ReturnType<typeof AddressActions.changeValidationStatus>) => ({
      ...state,
      isValid
    })
  ),
  on(
    AddressActions.changeZIEligibleAHPStatus,
    (state, { isZoneInondable }: ReturnType<typeof AddressActions.changeZIEligibleAHPStatus>) => ({
      ...state,
      isZoneInondable
    })
  ),
  on(AddressActions.init, state => ({
    ...state,
    ...initialState
  }))
);

export function reducer(state: AddressState | undefined, action: Action) {
  return addressReducer(state, action);
}

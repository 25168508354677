import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { Question } from 'src/app/core/models/question.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import {
  getMetropoleBareme,
  getMetropoleBaseName,
  getMetropoleCapPictureName,
  getMetropoleCityName,
  getMetropoleResidentName
} from 'src/app/shared/helpers/metropole-helper.service';
import { PopUpInfoVM } from 'src/app/shared/models/components/popupinfoVm';
import { ReferenceDataService } from 'src/app/shared/services/reference-data-service';
import { String } from 'typescript-string-operations';
import * as fromRoot from '../../../core/state/core.state';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromPath from '../../../core/state/path';

@Component({
  selector: 'app-preferential-rate',
  templateUrl: './preferential-rate.component.html',
  styleUrls: ['./preferential-rate.component.scss']
})
export class PreferentialRateComponent extends BaseComponent implements OnInit {
  preferentialRateOvered = false;
  noPreferentialRatemouseOvered = false;
  preferentialRateChosen = false;
  noPreferentialRateChosen = false;

  rfrInferiorOvered = false;
  rfrSuperiorOvered = false;
  rfrNoAnswerOvered = false;

  rfrInferiorChosen = false;
  rfrSuperiorChosen = false;
  rfrNoAnswerChosen = false;

  numberOfPeople = 0;
  plafondResources = 0;
  revenue = false;
  displayModal = false;

  cityName: string = '';

  path!: PathType;
  previousPath!: PathType;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepPreferentialRate,
    subStepNumber: 5
  };

  override question: Question = {
    libelle: this.resource.question.preferentialRate,
    popupInfo: new PopUpInfoVM(
      this.resource.infobulle.preferentialRate1,
      '',
      this.resource.infobulle.preferentialRate2,
      '',
      this.resource.infobulle.preferentialRatePlafondsMetropole
    )
  };

  public override currentPage = MatomoConstants.TARIFS;

  constructor(
    store: Store<fromRoot.State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router,
    private refDataService: ReferenceDataService
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    await this.OverrideQuestionComponent();
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async OverrideQuestionComponent() {
    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    this.previousPath = await lastValueFrom(
      this.store.select(fromPath.selectPreviousPathType).pipe(take(1))
    );
    this.question.libelle = String.format(
      this.resource.question.preferentialRate,
      getMetropoleResidentName(this.path)
    );

    this.question.popupInfo = new PopUpInfoVM(
      String.format(this.resource.infobulle.preferentialRate1, getMetropoleBaseName(this.path)),
      '',
      this.resource.infobulle.preferentialRate2,
      '',
      String.format(
        this.resource.infobulle.preferentialRatePlafondsMetropole,
        getMetropoleCapPictureName(this.path)
      )
    );
  }

  private async loadStateData() {
    var housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    this.cityName = String.format(
      this.resource.preferentialrate.noP2,
      getMetropoleCityName(this.path)
    );

    if (housingData.SaisieHabitation.TarifPreferentiel) {
      this.preferentialRateChosen = true;
      const payload = {
        libelle: this.resource.question.preferentialRateN,
        popupInfo: new PopUpInfoVM(this.resource.infobulle.preferentialRate2, '', '', '', '')
      } as Partial<Question>;
      this.store.dispatch(fromInfo.updateQuestion({ payload }));
    }

    if (housingData.SaisieHabitation.CompositionFoyer != undefined) {
      this.numberOfPeople = housingData.SaisieHabitation.CompositionFoyer;
      this.recalculatePlafondRessources();
    }

    if (housingData.SaisieHabitation.RFRInferieurPlafond) {
      this.rfrSuperiorChosen = housingData.SaisieHabitation.RFRInferieurPlafond;
    }

    if (housingData.SaisieHabitation.ConfirmationRFRInferieurPlafond) {
      this.rfrInferiorChosen = housingData.SaisieHabitation.ConfirmationRFRInferieurPlafond;
    }
  }

  async onPreferentialRateChosen() {
    this.preferentialRateChosen = !this.preferentialRateChosen;
    if (this.noPreferentialRateChosen) {
      this.noPreferentialRateChosen = false;
    }

    const payload = {
      libelle: this.resource.question.preferentialRateN,
      popupInfo: new PopUpInfoVM(this.resource.infobulle.preferentialRate2, '', '', '', '')
    } as Partial<Question>;
    this.store.dispatch(fromInfo.updateQuestion({ payload }));

    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { TarifPreferentiel: this.preferentialRateChosen }
      })
    );

    await this.updateStepper();
  }

  async onRfrSuperiorChosen() {
    this.rfrSuperiorChosen = !this.rfrSuperiorChosen;
    this.rfrInferiorChosen = false;
    this.rfrNoAnswerChosen = false;

    if (this.rfrSuperiorChosen === true) {
      this.store.dispatch(
        fromHousing.patchPreferentialType({
          payload: {
            RFRInferieurPlafond: this.rfrSuperiorChosen,
            ConfirmationRFRInferieurPlafond: this.rfrInferiorChosen
          }
        })
      );
      this.switchToHousingPath();
    }

    await this.updateStepper();
    await this.updateResumeStep(this.resource.resumestep.superior, false);

    this.router.navigate(['/numberpieces']);
  }

  async onRfrInferiorChosen() {
    this.rfrInferiorChosen = !this.rfrInferiorChosen;
    this.rfrSuperiorChosen = false;
    this.rfrNoAnswerChosen = false;
    const payload = {
      libelle: this.resource.question.preferentialRateF
    } as Partial<Question>;
    this.store.dispatch(fromInfo.updateQuestion({ payload }));

    if (this.rfrInferiorChosen === true) {
      this.store.dispatch(
        fromHousing.patchPreferentialType({
          payload: {
            RFRInferieurPlafond: !this.rfrInferiorChosen,
            ConfirmationRFRInferieurPlafond: this.rfrSuperiorChosen
          }
        })
      );
    }

    await this.updateStepper();
    this.displayModal = true;
  }

  async onRfrNoAnswerChosen() {
    this.rfrNoAnswerChosen = !this.rfrNoAnswerChosen;
    this.rfrSuperiorChosen = false;
    this.rfrInferiorChosen = false;

    const payload = {
      libelle: this.resource.question.preferentialRateF
    } as Partial<Question>;
    this.store.dispatch(fromInfo.updateQuestion({ payload }));

    if (this.rfrNoAnswerChosen === true) {
      this.store.dispatch(
        fromHousing.patchPreferentialType({
          payload: { RFRInferieurPlafond: undefined }
        })
      );
      this.switchToHousingPath();
    }
    this.tracker.trackEvent(MatomoConstants.AHP_TO_AHC, MatomoConstants.REVENUS_FOYER_3);

    await this.updateStepper();
    await this.updateResumeStep(this.resource.resumestep.superior, true);
    this.router.navigate(['/numberpieces']);
  }

  async onNoPreferentialRateChosen() {
    this.noPreferentialRateChosen = !this.noPreferentialRateChosen;
    if (this.preferentialRateChosen) {
      this.preferentialRateChosen = false;
    }

    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { TarifPreferentiel: this.preferentialRateChosen }
      })
    );

    this.switchToHousingPath();

    this.tracker.trackEvent(MatomoConstants.AHP_TO_AHC, MatomoConstants.REVENUS_FOYER_1);

    await this.updateStepper();
    await this.updateResumeStep(this.resource.resumestep.superior, true);
    this.router.navigate(['/numberpieces']);
  }

  addPerson($event: any) {
    if (this.numberOfPeople < 99) {
      this.numberOfPeople++;

      this.recalculatePlafondRessources();

      this.dispatchNumberOfPeople();
    }
  }

  removePerson($event: any) {
    if (this.numberOfPeople > 0) {
      this.numberOfPeople--;

      this.recalculatePlafondRessources();

      this.dispatchNumberOfPeople();
    }
  }

  triggerChange() {
    this.recalculatePlafondRessources();
    this.dispatchNumberOfPeople();
  }

  private dispatchNumberOfPeople() {
    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { CompositionFoyer: this.numberOfPeople }
      })
    );
  }

  recalculatePlafondRessources() {
    let referenceScale = getMetropoleBareme(
      this.path != PathType.HOUSING ? this.path : this.previousPath
    );
    if (referenceScale) {
      if (this.numberOfPeople > 0) {
        if (this.numberOfPeople <= 6) {
          this.plafondResources = referenceScale[this.numberOfPeople].Montant;
        } else {
          const extra = referenceScale[0].Montant;
          const six = referenceScale[6].Montant;
          this.plafondResources = six + extra * (this.numberOfPeople - 6);
        }
      } else {
        this.plafondResources = 0;
      }
    }
  }

  switchToRevenue() {
    const payload = {
      libelle: this.resource.question.preferentialRateF,
      popupInfo: new PopUpInfoVM(
        this.resource.infobulle.preferentialRateRevenue1,
        this.resource.infobulle.preferentialRateRevenue2,
        '',
        this.resource.infobulle.preferentialRateRevenue3,
        this.resource.infobulle.preferentialRateRevenueImage
      )
    } as Partial<Question>;
    this.store.dispatch(fromInfo.updateQuestion({ payload }));
    this.revenue = true;
  }

  async validatePreferentialRate() {
    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { ConfirmationRFRInferieurPlafond: true }
      })
    );

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    this.tracker.trackEvent(
      String.format(MatomoConstants.AHC_TO_METROPOLE, this.path),
      MatomoConstants.REVENUS_FOYER_1
    );

    this.displayModal = false;

    await this.updateStepper();
    await this.updateResumeStep(this.resource.resumestep.inferior, false);
    this.router.navigate(['/numberpieces']);
  }

  switchToHousingPath() {
    this.store.dispatch(
      fromPath.updateType({
        pathType: PathType.HOUSING
      })
    );
    this.store.dispatch(
      fromPath.updatePreviousType({
        previousPathType: this.path != PathType.HOUSING ? this.path : this.previousPath
      })
    );
  }

  cancelPreferentialRate() {
    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { ConfirmationRFRInferieurPlafond: false }
      })
    );

    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { RFRInferieurPlafond: undefined }
      })
    );
    this.displayModal = false;
  }

  async onNoPreferentialRate() {
    this.store.dispatch(
      fromHousing.patchPreferentialType({
        payload: { TarifPreferentiel: false }
      })
    );
    this.switchToHousingPath();
    this.tracker.trackEvent(MatomoConstants.AHP_TO_AHC, MatomoConstants.REVENUS_FOYER_2);

    await this.updateStepper();
    await this.updateResumeStep(this.resource.resumestep.superior, true);
    this.router.navigate(['/numberpieces']);
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.preferentialrate
    );

    if (subtitleS) {
      subtitleS.isValid = true;
    }

    let subtitleN;
    subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.numberpieces
    );

    if (subtitleN) {
      subtitleN.isVisible = true;
      subtitleN.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep(libelle: string, skip: boolean) {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(
      x => x.id == this.resource.resumestep.preferentialrate
    );

    if (stepResumeStep) {
      if (!skip) {
        stepResumeStep.step = libelle;
        stepResumeStep.isVisible = true;
      } else {
        stepResumeStep.step = '';
        stepResumeStep.isVisible = false;
      }
    } else {
      if (!skip)
        tmpResumeStep.steps?.push({
          id: this.resource.resumestep.preferentialrate,
          step: libelle,
          url: this.resource.resumestep.preferentialrate,
          isVisible: true
        });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { Observable, finalize, lastValueFrom, take } from 'rxjs';
import { CountryModel } from 'src/app/api/models/country.model';
import { PhonePrefixes } from 'src/app/api/models/phonePrefixes.model';
import { ConsentService } from 'src/app/api/services/consent.service';
import { CountryService } from 'src/app/api/services/country.service';
import { MemberService } from 'src/app/api/services/member.service';
import { PriceService } from 'src/app/api/services/price.service';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { Civility, civiliteObject } from 'src/app/core/models/civility.model';
import { BackendContextSouscripteur } from 'src/app/core/models/context.model';
import { Info } from 'src/app/core/models/info.model';
import { Question } from 'src/app/core/models/question.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { State } from 'src/app/core/state/core.state';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { CANAL_VENTE_C } from 'src/app/shared/constants/context.constants';
import { TOKEN } from 'src/app/shared/constants/localstorage.constants';
import {
  BIRTHDAY,
  BIRTHDAY_COUNTRY,
  BIRTHDAY_PLACE,
  CIVILITY,
  CONFIRM_EMAIL,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  PHONE_GROUP,
  PHONE_PREFIX
} from 'src/app/shared/constants/subscriber-form.constants';
import { NAME_REGEX } from 'src/app/shared/constants/validators.constants';
import { getMetropoleBaseName } from 'src/app/shared/helpers/metropole-helper.service';
import { SubscriberCGVm } from 'src/app/shared/models/components/subscriberCGVm';
import { ApiRequestBodyUtils } from 'src/app/shared/utils/apiRequestBodyUtils';
import { ApiResponseBodyUtils } from 'src/app/shared/utils/apiResponseBodyUtils';
import { DateUtils } from 'src/app/shared/utils/dateUtils';
import { MemberEmailValidator } from 'src/app/shared/validators/member-email.validator';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import * as fromAddress from '../../../core/state/address';
import * as fromContext from '../../../core/state/context';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromOffer from '../../../core/state/offer-customization';
import * as fromPath from '../../../core/state/path';
import * as fromSubscriber from '../../../core/state/subscriber';
import * as fromTarification from '../../../core/state/tarification';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriberComponent extends BaseComponent implements OnInit {
  override step: Step = {
    step: this.resource.header.stepContact,
    stepNumber: 2,
    subStep: this.resource.header.subStepSubscriber,
    subStepNumber: 0
  };

  override question: Question = {
    libelle: this.resource.question.subscriber,
    popupInfo: undefined
  };

  override info: Info = {
    title: this.resource.infotop.beforeLastStep,
    secondTitle: '',
    subTitle: this.resource.infotop.beforeLastStepB,
    logo: this.resource.infotop.logoTime,
    isVisible: false
  };

  maxDate: Date;

  subscriberForm!: FormGroup;
  phoneGroup!: FormGroup;
  stateDataContextSubscriber: BackendContextSouscripteur | null | undefined;

  civilities: Civility[] = civiliteObject;
  selectedCivility: Civility | undefined;

  path!: PathType;
  subscriberCGVm!: SubscriberCGVm;

  estClient: boolean = false;

  countries = new Array<CountryModel>();
  phoneMember!: string | null;
  phonePrefixMember: PhonePrefixes[] | undefined;
  filteredCountries = new Array<CountryModel>();
  context!: string;

  emailStep = new FormControl();
  emailStepConfirm = false;

  memberLoginPopup: boolean = false;
  initAccountPopup: boolean = false;
  emailMember: string = '';

  get civility() {
    return this.subscriberForm?.get(CIVILITY);
  }

  get firstName() {
    return this.subscriberForm?.get(FIRST_NAME);
  }

  get lastName() {
    return this.subscriberForm?.get(LAST_NAME);
  }

  get birthday() {
    return this.subscriberForm?.get(BIRTHDAY);
  }

  get birthdayPlace() {
    return this.subscriberForm?.get(BIRTHDAY_PLACE);
  }

  get birthdayCountry() {
    return this.subscriberForm?.get(BIRTHDAY_COUNTRY);
  }

  get email() {
    return this.subscriberForm?.get(EMAIL);
  }

  get confirmEmail() {
    return this.subscriberForm?.get(CONFIRM_EMAIL);
  }

  get phone() {
    return this.subscriberForm?.get(PHONE_GROUP)?.get(PHONE);
  }

  get phonePrefix() {
    return this.subscriberForm?.get(PHONE_GROUP)?.get(PHONE_PREFIX);
  }

  get brand() {
    return environment.brand;
  }

  isLoading: boolean = false;

  constructor(
    private router: Router,
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private priceService: PriceService,
    private consentService: ConsentService,
    private countryService: CountryService,
    private memberService: MemberService,
    private memberEmailValidator: MemberEmailValidator
  ) {
    super(store, resources, tracker);

    let today = new Date();
    today = new Date(today.setFullYear(today.getFullYear() - 18));
    this.maxDate = new Date(today.setDate(today.getDate() - 1));
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    // Todo: Edit the following lines when developing HM
    this.subscriberCGVm = new SubscriberCGVm(
      String.format(
        this.resource.subscriber.text1CG,
        String.format('Assurance Habitation {0}', getMetropoleBaseName(this.path))
      ),
      this.resource.subscriber.text2CG,
      this.resource.subscriber.text3CG,
      this.resource.subscriber.text3bisCG,
      this.resource.subscriber.text4CG,
      this.resource.subscriber.text5CG
    );

    this.updateResumeStepVisibility(true);
    await this.loadCountries();
    this.initEmailStep();

    await this.loadStateData();

    // Désactiver la modification du formulaire dans le cas d'un client (non prospect) si la valeur est connue
    if (this.estClient) {
      this.disableExistingContextDataFormControls();
    }
  }

  private initEmailStep() {
    this.emailStep = new FormControl<string | null>('', {
      validators: [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
      ],
      asyncValidators: [this.memberEmailValidator.validate.bind(this.memberEmailValidator)],
      nonNullable: true,
      updateOn: 'change'
    });
  }

  async confirmEmailStep() {
    this.emailStepConfirm = true;
    var stateContextData = await lastValueFrom(
      this.store.select(fromContext.selectContextData).pipe(take(1))
    );
    this.initForm();
    // Reset subscriber infos
    this.subscriberForm.reset();
    this.store.dispatch(fromSubscriber.resetSubscriber());

    this.memberService.emailExists(this.emailStep.value).subscribe(data => {
      if (data === false) {
        this.initFormNotMember();
      } else {
        if (stateContextData?.canalVente === CANAL_VENTE_C) {
          this.memberService.getByEmail(this.emailStep.value).subscribe({
            next: data => {
              this.initFormMember(data);
            }
          });
        } else {
          this.memberIsEnrolled(this.emailStep.value).subscribe(isEnrolled => {
            if (isEnrolled) {
              this.memberLoginPopup = true;
              this.emailMember = this.emailStep.value;
              var token = JSON.parse(localStorage.getItem(TOKEN) || '""');
              if (token) {
                this.memberService.getUserInfoAuth().subscribe(data => {
                  this.initFormMember(data);
                });
              }
            } else {
              this.initAccountPopup = true;
            }
          });
        }
      }
    });
  }

  memberIsEnrolled(email: string): Observable<boolean> {
    return this.memberService.isEnrolled(email);
  }

  initFormMember(data: any) {
    var datePipe = new DatePipe('fr-FR');
    var country = this.countries.find(x => x.countryNameFr == data.birthCountry)!;
    this.selectedCivility = this.civilities.find(obj => {
      return obj.value === data.civility || obj.id === data.civilityTypeId;
    });
    this.civility?.setValue(this.selectedCivility);
    this.lastName?.setValue(data.lastName);
    this.firstName?.setValue(data.firstName);
    this.birthday?.setValue(datePipe.transform(data.birthDate, 'dd/MM/yyyy'));
    this.birthdayCountry?.setValue(country);
    this.birthdayPlace?.setValue(data.birthCity);
    this.email?.setValue(this.emailStep.value);
    this.phone?.reset();
    console.log(data);
    if (data.phoneNumber) {
      this.phone?.setValue(data.phoneNumber);
      this.phonePrefix?.setValue(this.phonePrefixMember?.find(x => x.prefix === data.phonePrefix));
    }
    if (data.cellPhone) {
      this.phone?.setValue(data.cellPhone);
      this.phonePrefix?.setValue(this.phonePrefixMember?.find(x => x.prefix === data.phonePrefix));
    }
    this.updateFormControlsState();
  }

  initFormNotMember() {
    this.initForm();
    this.subscriberForm.addControl(PHONE_GROUP, this.phoneGroup);
    this.email?.setValue(this.emailStep.value);
    this.email?.disable();
  }

  updateFormControlsState(): void {
    const updateControlState = (control: AbstractControl | null, enable: boolean) => {
      if (control) {
        if (enable) {
          control.enable();
        } else {
          control.disable();
        }
      }
    };

    Object.keys(this.subscriberForm.controls).forEach(key => {
      const control = this.subscriberForm.get(key);
      if (key !== PHONE_GROUP) {
        const shouldEnable =
          control?.value === null || control?.value === '' || control?.value === undefined;
        updateControlState(control, shouldEnable);
      } else {
        const shouldEnable =
          this.phone?.value === null || this.phone?.value === '' || this.phone?.value === undefined;
        updateControlState(this.phoneGroup, shouldEnable);
      }
    });

    this.subscriberForm.updateValueAndValidity();
  }

  async loadCountries() {
    this.countries = await lastValueFrom(this.countryService.GetCountries().pipe(take(1)));

    // Sort countries by country code with France as first item of the list
    this.countries.sort((c1, c2) => {
      const franceCode = 'FR';
      if (c1.countryCode === franceCode) return -1;
      else if (c2.countryCode === franceCode) return 1;
      else return c1.countryCode.localeCompare(c2.countryCode); // Usual string comparison
    });

    this.filteredCountries = this.countries;
  }

  private initForm() {
    this.subscriberForm = new FormGroup(
      {
        civility: new FormControl<Civility[] | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        firstName: new FormControl<string | null>(null, {
          validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_REGEX)
          ],
          nonNullable: true
        }),
        lastName: new FormControl<string | null>(null, {
          validators: [
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(NAME_REGEX)
          ],
          nonNullable: true
        }),
        birthday: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        birthdayPlace: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        birthdayCountry: new FormControl<CountryModel | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        email: new FormControl<string | null>('', {
          validators: [
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
          ],
          asyncValidators: [this.memberEmailValidator.validate.bind(this.memberEmailValidator)],
          nonNullable: true
        }),
        consentValid: new FormControl<boolean | undefined>(undefined, {
          validators: this.brand === 'HM' ? [Validators.required] : [],
          nonNullable: false
        })
      },
      {
        updateOn: 'change'
      }
    );
  }
  public validCheckboxes($event: any) {
    if (!$event.voies.some((c: any) => c.isCheck === undefined)) {
      this.subscriberForm.controls['consentValid'].setValue(true);
    }
  }

  disableExistingContextDataFormControls() {
    if (this.stateDataContextSubscriber?.civilite)
      this.subscriberForm.controls['civility'].disable();
    if (this.stateDataContextSubscriber?.prenom)
      this.subscriberForm.controls['firstName'].disable();
    if (this.stateDataContextSubscriber?.nom) this.subscriberForm.controls['lastName'].disable();
    if (
      this.stateDataContextSubscriber?.paysNaissance &&
      this.countries.find(
        c =>
          c[this.brand === 'HM' ? 'countryCode' : 'countryNameFr'] ===
          this.stateDataContextSubscriber?.paysNaissance
      )
    ) {
      this.subscriberForm.controls['birthdayCountry'].disable();
    }
    if (this.stateDataContextSubscriber?.dateNaissance)
      this.subscriberForm.controls['birthday'].disable();
    if (this.stateDataContextSubscriber?.villeNaissance)
      this.subscriberForm.controls['birthdayPlace'].disable();
    if (this.stateDataContextSubscriber?.email) {
      this.subscriberForm.controls['email'].disable();
      this.subscriberForm.controls['confirmEmail'].disable();
    }
  }

  private async loadStateData() {
    this.store.dispatch(
      fromSubscriber.changeValidationStatus({
        isValid: false
      })
    );

    let contextId = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );
    this.context = contextId!;

    var stateContextData = await lastValueFrom(
      this.store.select(fromContext.selectContextData).pipe(take(1))
    );

    this.stateDataContextSubscriber = stateContextData?.souscripteur;

    var res = await lastValueFrom(
      this.store.select(fromSubscriber.selectSubscriber).pipe(take(1))
    )!;

    this.estClient = res.subscriber?.EstClient || false;
    this.selectedCivility = this.civilities.find(obj => {
      return obj.value === res.subscriber?.Civilite;
    });
    this.lastName?.setValue(res.subscriber?.Nom);
    this.firstName?.setValue(res.subscriber?.Prenom);
    this.birthday?.setValue(DateUtils.IsoToDayMonthYear(res.subscriber?.DateNaissance));
    this.birthdayPlace?.setValue(res.subscriber?.LieuNaissance);
    this.birthdayCountry?.setValue(
      this.brand === 'HM'
        ? this.countries.find(x => x.countryCode === res.subscriber?.PaysNaissance)!
        : this.countries.find(x => x.countryNameFr === res.subscriber?.PaysNaissance)!
    );
    this.email?.setValue(res.subscriber?.Email);
    if (this.estClient && res.subscriber?.Email) this.confirmEmail?.setValue(res.subscriber?.Email);
    this.phone?.setValue(res.subscriber?.Telephone);
    if (this.estClient) {
      this.subscriberForm.controls['consentValid'].clearValidators();
    }
  }

  async filterCountries($event: any) {
    let query = $event.query;
    if (query != null) {
      var trimmedQuery = query
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLocaleLowerCase('fr-FR');
      this.filteredCountries = this.countries.filter(item => {
        return item.countryNameFr
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLocaleLowerCase('fr-FR')
          .includes(trimmedQuery);
      });
    } else {
      this.filteredCountries = this.countries;
    }
  }

  addPhoneControls(phoneControls: FormGroup) {
    this.initForm();
    this.phoneGroup = phoneControls;
    this.subscriberForm.addControl(PHONE_GROUP, phoneControls);
    this.subscriberForm.updateValueAndValidity();
  }

  getPhonePrefixes(event: PhonePrefixes[]) {
    this.phonePrefixMember = event;
  }

  async confirmStep() {
    this.isLoading = true;
    this.store.dispatch(
      fromSubscriber.patchSubscriber({
        payload: {
          Civilite: this.civility?.value.value,
          Nom: this.lastName?.value,
          Prenom: this.firstName?.value,
          Email: this.email?.value,
          Indicatif: this.phone?.value ? this.phonePrefix?.value.prefix : '',
          Telephone: this.phone?.value,
          DateNaissance: DateUtils.DayMonthYearToIso(this.birthday?.value) ?? undefined,
          LieuNaissance: this.birthdayPlace?.value,
          PaysNaissance: this.birthdayCountry?.value.countryNameFr
        }
      })
    );

    // Retrieve context
    const context = await lastValueFrom(
      this.store.select(fromContext.selectContextId).pipe(take(1))
    );

    // Retrieve product
    const product = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    // Retrieve address
    const stateAddress = await lastValueFrom(
      this.store.select(fromAddress.selectAddress).pipe(take(1))
    );

    // Retrieve housing informations
    const stateHousing = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    // Retrieve selected formule informations
    const stateOffer = await lastValueFrom(
      this.store.select(fromOffer.selectOfferCustomization).pipe(take(1))
    );

    const body = ApiRequestBodyUtils.GenerateTarifCreateRequestBody(
      context!,
      product,
      this.brand,
      stateAddress.adresseType,
      stateAddress.addresseSelection,
      stateAddress.addresseManuelle,
      stateAddress.adresseEsh,
      stateHousing.SaisieHabitation,
      {
        ...stateOffer.SaisieFormule,
        FormuleSelectionnee: undefined // Recalculate all formules
      },
      stateOffer.FormuleRecommandee!
    );

    let consent = (
      await lastValueFrom(this.store.select(fromSubscriber.selectSubscriber).pipe(take(1)))
    ).subscriber?.Consentement;

    this.priceService
      .GetProductPrices(body)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: res => {
          this.store.dispatch(
            fromSubscriber.changeValidationStatus({
              isValid: true
            })
          );

          // Store returned informations about formules in the state
          const tarifInfos = ApiResponseBodyUtils.ExtractTarifResponseBody(res);
          this.store.dispatch(
            fromTarification.patchTarification({
              payload: tarifInfos
            })
          );

          // Send consent informations for HM case
          if (consent && this.brand === 'HM') {
            const bodyConsent = ApiRequestBodyUtils.GeneratePostConsentRequestBody(
              this.context,
              consent.IdLibelle,
              consent.VoiesConsentement
            );
            this.consentService.PostConsentement(bodyConsent).subscribe({
              error: error => {
                console.error("Une erreur s'est produite :", error);
              },
              complete: () => {
                this.router.navigate(['/rate']);
              }
            });
          } else {
            this.router.navigate(['/rate']);
          }
        },
        error: error => {
          console.error("Une erreur s'est produite :", error);
        }
      });

    await this.updateStepper();
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.offer
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.subscriber
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.rate
    );

    if (subtitleN) subtitleN.isActive = true;

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  ToLowerCase(event: any) {
    let value = event.target.value;
    event.target.value = value.toLowerCase();
  }

  ToCapitalize(event: any) {
    let value = event.target.value;
    if (value[0] != undefined) event.target.value = value[0].toUpperCase() + value.slice(1);
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  closeDialogLogin() {
    this.memberLoginPopup = false;
    this.emailStepConfirm = false;
  }

  closeInitPopup() {
    this.initAccountPopup = false;
    this.emailStepConfirm = true;
    this.memberLoginPopup = true;
  }

  closeAuthPopup() {
    this.memberLoginPopup = false;
    this.emailStepConfirm = false;
  }

  closeValidLoginAndMfa(event: boolean) {
    var token = JSON.parse(localStorage.getItem(TOKEN) || '""');
    if (token && event === true) {
      this.subscriberForm.addControl(PHONE_GROUP, this.phoneGroup);
      this.memberService.getUserInfoAuth().subscribe(data => {
        this.initFormMember(data);
      });
    }
  }
}

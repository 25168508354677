<app-header [headerStep]="headerStep" [local]="true"></app-header>
<ng-container *ngIf="context && context.contextId">
  <div class="mb-6">
    <ng-container *ngIf="confirmQuoteVm">
      <div class="container mx-auto">
        <div class="my-7 text-center space-y-2">
          <p class="titleQuote">{{ resource.confirmquote.title }}</p>
          <p class="subtitleQuote">{{ confirmQuoteVm.subtitle }}</p>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-x-4 md:gap-y-4">
          <div class="containerPanel-QC mx-auto" *ngIf="this.context && this.context.contextId">
            <div *ngIf="iB_CP" class="panelCPlus-QC pt-9">
              <p class="title-QC text-center">{{ resource.confirmquote.titlePanel }}</p>
              <div class="panelCPlusLogo-QC mx-6 mt-4 mb-2"></div>
              <div class="mx-16 space-y-1">
                <p class="rateMonth">
                  {{ confirmQuoteVm.rateMonth }}
                  <span class="textMonth"> {{ resource.confirmquote.text1 }}</span>
                </p>
              </div>
            </div>

            <div *ngIf="iB_C" class="panelC-QC pt-9">
              <p class="titleC-QC text-center">{{ resource.confirmquote.titlePanel }}</p>
              <div class="panelCLogo-QC mx-6 mt-4 mb-2"></div>
              <div class="mx-16 space-y-1">
                <p class="rateMonthC">
                  {{ confirmQuoteVm.rateMonth }}
                  <span class="textMonthC"> {{ resource.confirmquote.text1 }}</span>
                </p>
              </div>
            </div>

            <div *ngIf="iB_P" class="panelCPlus-QC pt-9">
              <p class="title-QC text-center">{{ resource.confirmquote.titlePanel }}</p>
              <div [ngClass]="productClass" class="panelPLogo-QC mx-6 mt-4 mb-2"></div>
              <div class="mx-16 space-y-1">
                <p class="rateMonth">
                  {{ confirmQuoteVm.rateMonth }}
                  <span class="textMonth"> {{ resource.confirmquote.text1 }}</span>
                </p>
              </div>
            </div>

            <div *ngIf="iB_S" class="panelCPlus-QC pt-9">
              <p class="title-QC text-center">{{ resource.confirmquote.titlePanel }}</p>
              <div class="panelSLogo-QC mx-6 mt-4 mb-2"></div>
              <div class="mx-16 space-y-1">
                <p class="rateMonth">
                  {{ confirmQuoteVm.rateMonth }}
                  <span class="textMonth"> {{ resource.confirmquote.text1 }}</span>
                </p>
              </div>
            </div>

            <div class="containerTextSB-QC text-left mt-8 pb-8 mx-6 space-y-5">
              <div *ngFor="let option of confirmQuoteVm.options" class="flex">
                <p class="flex-3">{{ option.name }}</p>
                <p class="flex-1 textB-QC text-right">{{ option.valueShow }}</p>
              </div>
            </div>
          </div>
          <!-- ICI -->
          <div class="flex flex-row justify-center items-center md:items-start">
            <div class="mt-8 md:mt-0">
              <div class="titleDoc-CC mb-4 md:text-center">
                <p>{{ resource.confirmquote.docDownload }}</p>
              </div>
              <div class="space-y-3">
                <div class="containerSubtitleDoc-CC">
                  <div class="mx-auto my-2">
                    <a (click)="DownloadDocument('DEV')" class="flex py-4 px-4 cursor-pointer">
                      <div class="flex-grow">
                        <p class="subtitleDoc-CC">{{ resource.confirmquote.quote }}</p>
                      </div>
                      <div class="flex-none w-5">
                        <img [src]="resource.confirmcontract.logoPdf" />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="containerSubtitleDoc-CC">
                  <div class="mx-auto my-2">
                    <a (click)="DownloadDocument('FIC')" class="flex py-4 px-4 cursor-pointer">
                      <div class="flex-grow">
                        <p class="subtitleDoc-CC">
                          {{ resource.confirmquote.docFIC }}
                        </p>
                      </div>
                      <div class="flex-none w-5">
                        <img [src]="resource.confirmcontract.logoPdf" /></div
                    ></a>
                  </div>
                </div>
                <div class="containerSubtitleDoc-CC">
                  <div class="mx-auto my-2">
                    <a (click)="DownloadDocument('CG')" class="flex py-4 px-4 cursor-pointer">
                      <div class="flex-grow">
                        <p class="subtitleDoc-CC">{{ resource.confirmquote.generalCondition }}</p>
                      </div>
                      <div class="flex-none w-5">
                        <img [src]="resource.confirmcontract.logoPdf" /></div
                    ></a>
                  </div>
                </div>
                <div class="containerSubtitleDoc-CC">
                  <div class="flex mx-auto my-2">
                    <a (click)="DownloadDocument('DIPA')" class="flex py-1 px-4 cursor-pointer">
                      <div class="flex-grow">
                        <p class="subtitleDoc-CC">
                          {{ resource.confirmquote.productInformation }}
                        </p>
                      </div>
                      <div class="flex-none w-5 my-3">
                        <img [src]="resource.confirmcontract.logoPdf" /></div
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8 mb-6">
          <button
            pButton
            [disabled]="disableNextStep"
            class="mx-auto my-4 button-NS width-max iconSideLabel"
            [label]="resource.confirmquote.suscribeOffer"
            icon="pi pi-arrow-right"
            iconPos="right"
            (click)="nextStep()"></button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="container mx-auto h-28 md:h-40">
    <app-footer class="footer"></app-footer>
  </div>
  <app-web-callback></app-web-callback>

  <p-dialog
    [(visible)]="displayGdaModal"
    [modal]="true"
    [style]="{ width: '330px', height: '285px', margin: '1rem' }"
    [closable]="false"
    [showHeader]="false"
    [baseZIndex]="10000">
    <p class="title-P my-5">{{ resource.confirmquote.titleGDAModal }}</p>
    <p class="text-P my-5">{{ resource.confirmquote.textGDAModal }}</p>
    <div class="flex justify-center mt-10">
      <button
        pButton
        type="button"
        class="button-NS w-full"
        [label]="resource.confirmquote.validGDA"
        (click)="validGDA()"></button>
    </div>
  </p-dialog>
  <p-dialog
    [(visible)]="displayContractNotEligibleModal"
    [modal]="true"
    [style]="{ width: '380px', height: '300px', margin: '1rem' }"
    [closable]="true"
    [showHeader]="true"
    [baseZIndex]="10000">
    <p-header>
      <p>{{ resource.confirmquote.titleContractEligModal }}</p>
    </p-header>
    <p class="text-P my-5">{{ contractNotEligibleModalText }}</p>
  </p-dialog>
</ng-container>
<p-dialog
  [(visible)]="displayContextErrorModal"
  [modal]="true"
  [style]="{ width: '380px', height: '200px', margin: '1rem' }"
  [closable]="true"
  [showHeader]="true"
  [baseZIndex]="10000"
  (onHide)="closeErrorModal()">
  <p-header>
    <p>{{ resource.errorquote.title }}</p>
  </p-header>
  <p class="text-P my-5">
    {{ resource.errorquote.message }}
  </p>
</p-dialog>

export class MatomoConstants {
  /* #region Category */
  public static readonly ADDRESS = 'Informations sur le logement - adresse';
  public static readonly ADDRESS_ESH = ' Informations sur le logement - adresse ESH';
  public static readonly TYPE_HABITATION = "Informations sur le logement - type d'habitation";
  public static readonly OCCUPATION = 'Informations sur le logement - occupation';
  public static readonly RESIDENCE = 'Informations sur le logement - résidence';
  public static readonly REVENUS_FOYER_1 = 'Informations sur le logement - revenus du foyer (1)';
  public static readonly REVENUS_FOYER_2 = 'Informations sur le logement - revenus du foyer (2)';
  public static readonly REVENUS_FOYER_3 = 'Informations sur le logement - revenus du foyer (3)';
  public static readonly REVENUS_FOYER_4 =
    'Informations sur le logement - validation PopUp Revenu inférieur (3)';
  public static readonly NOMBRE_PIECES = 'Informations sur le logement - nombre de pièces';
  public static readonly ANTECEDENTS_SINITRES =
    'Informations sur le logement - antécédents de sinistres';
  public static readonly DEPENDANCES = ' Informations sur le logement - dépendances';
  public static readonly INSTALLATIONS = ' Informations sur le logement - installations';
  public static readonly OBJETS_VALEURS = 'Informations sur le logement - objets de valeur';
  public static readonly INFO_SOUSCRIPTEUR = 'Informations du souscripteur';
  public static readonly TARIFS = 'Proposition de tarifs';
  public static readonly PERSO_OBJET_VALEUR = "Personalisation de l'offre - Objets de valeur";
  public static readonly OPTIONS = "Personalisation de l'offre - Options";
  public static readonly ASSURANCE_SCOLAIRE = "Personalisation de l'offre - Assurance scolaire";
  public static readonly SYNTHESE_DEVIS = 'Synthèse du devis';
  public static readonly BENEFICIAIRE_SELF = 'Bénéficiaire du contrat - Moi-même';
  public static readonly BENEFICIAIRE_OTHER = 'Bénéficiaire du contrat - Autre';
  public static readonly INFO_SOUSCRIPTEUR_2 = 'Informations du souscripteur';
  public static readonly CHOIX_SERVICES = 'Choix des services';
  public static readonly CHOIX_SERVICES_SP =
    'Choix des services - Envoi attestation bailleur - {0}';
  public static readonly CHOIX_SERVICES_S =
    'Choix des services - Envoi attestation bailleur - Social';
  public static readonly RESILIATION_CONCURRENCE_1 = 'Résiliation concurrence (1)';
  public static readonly RESILIATION_CONCURRENCE_2 =
    "Résiliation concurrence (2) - popin avec la date d`'effet";
  public static readonly RESILIATION_CONCURRENCE_3 =
    'Résiliation concurrence (3) - infos supplémentaires';
  public static readonly RESILIATION_CONCURRENCE_4 =
    "Résiliation concurrence (2) - popin moins d'un an";
  public static readonly PAIEMENT = 'Paiement';
  public static readonly SIGNATURE = 'Documents contractuels';
  public static readonly CONFIRM_PROSPECT = 'Confirmation pour prospect';

  public static readonly ARRET_PARCOURS = 'Arrêt parcours';

  /* #endregion */

  /* #region TYPES */
  public static readonly STEPPER_TYPE = 'Stepper';
  public static readonly WEB_CALLBACK_TYPE = 'Web callback';
  /* #endregion */

  /* #region Actions */
  public static readonly MANUAL_ADDRESS = "Saisie manuelle d'adresse";
  public static readonly OCCUPATION_AHC = "Occupation : propriétaire et produit différent d'AHC";
  public static readonly RESIDENCE_AHC = "Résidence : secondaire et produit différent d'AHC";
  /* #endregion */

  public static readonly STEPPER = 'Bouton stepper cliqué';
  public static readonly AHP_TO_AHC = 'Changement de produit de AHP vers AHC';
  public static readonly AHC_TO_METROPOLE = 'Changement de produit de AHC vers A{0}';
  public static readonly AHS_TO_AHC = 'Changement de produit de AHS vers AHC';
  public static readonly HOUSING_OTHER = "Type d'habitation : Autre";
  public static readonly INONDATIONS = '+3 inondations sur 5 ans';
  public static readonly TOO_MANY_ROOMS = 'Plus de 13 pièces';
  public static readonly STOP = 'Arrêt du parcours';
  public static readonly RECALL = "Bouton 'Etre rappelé cliqué'";
  public static readonly OPPOSITION = "Bouton 'Je m'oppose cliqué";
  public static readonly ACTIVATE_LATER = "Bouton 'Activer ce service plus tard cliqué";
  public static readonly NO_OCCUPATION =
    "bouton 'Non' pour la question 'occupez-vous déjà votre logement' cliqué";
  public static readonly JUMP_STEP = "Bouton 'passer cette étape' cliqué";
  public static readonly CANCEL = "Bouton 'annuler' cliqué";
  public static readonly LESSORNOTFIND = "Bouton 'autre bailleur' cliqué";

  public static readonly CONTENT_INTERACTION = 'Content Interaction';
}

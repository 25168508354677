import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AddressState } from './address.state';

export const selectAddress = createFeatureSelector<AddressState>('address');

export const selectAddressManuelleGroupData = createSelector(
  selectAddress,
  (state: AddressState) => state.addresseManuelle
);

export const selectAddressEshGroupData = createSelector(
  selectAddress,
  (state: AddressState) => state.adresseEsh
);

export const selectAddressSelectionGroupData = createSelector(
  selectAddress,
  (state: AddressState) => state.addresseSelection
);

export const selectAddressTypeData = createSelector(
  selectAddress,
  (state: AddressState) => state.adresseType
);

export const selectAddressValid = createSelector(
  selectAddress,
  (state: AddressState) => state.isValid
);

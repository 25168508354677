export const environment = {
  production: true,
  development: false,
  test: false,
  brand: 'Vyv',
  apiV2RootUrl: 'https://dev-02.assurance.vyv-conseil.fr/api/',
  baseUrl: 'https://dev-02.assurance-habitation.vyv-conseil.fr/',
  matomotoKey: 'CHANGEME',
  appInsightsKey: '886dda55-c8da-4007-bae9-81b529bb372b',
  assConfort: 'https://saboiardbackofficely7rr.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Confort_Tableau%20des%20garanties.pdf',
  assMetropole: 'https://saboiardbackofficely7rr.blob.core.windows.net/public/Plaquette_Assurance_Habitation_{0}_Tableau%20des%20garanties.pdf',
  assSolidaire:
    'https://saboiardbackofficely7rr.blob.core.windows.net/public/Plaquette_Assurance_Habitation_Solidaire_Tableau%20des%20garanties.pdf',
  matomoSiteId: 6,
  matomoHostUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/',
  matomoScriptUrl: 'https://matomo-recette.vyv-extension-essai.aws.mgen.fr/js/container_682wvypB.js',
  modeleCourrierResiliation: 'https://saboiardbackofficely7rr.blob.core.windows.net/public/ModeleCourrier.docx',
  msalConfig: {
    auth: {
      clientId: '5efe220a-c830-480c-9ef1-9e66a9e26d19',
      authority: 'https://login.microsoftonline.com/6f12f368-4cb1-4b07-9574-dd2cdf95d9c1',
      redirectUri: 'https://dev-02.assurance-habitation.vyv-conseil.fr/'
    }
  },
  apiConfig: {
    scopes: ['api://boiard-dev-02-back-office/Read.All', 'api://boiard-dev-02-back-office/Write.All'],
    uri: 'https://dev-02.assurance.vyv-conseil.fr/api/'
  }
};

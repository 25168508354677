import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Guid } from 'guid-typescript';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, map, take } from 'rxjs';
import { Policies } from 'src/app/api/models/policies.model';
import { ClientAdvisorService } from 'src/app/api/services/clientAdvisor.service';
import { PoliciesService } from 'src/app/api/services/policies.service';
import { ProductVersionService } from 'src/app/api/services/product-version.service';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import {
  EcheancePrelevement,
  SaisiePaiement,
  TypePromo
} from 'src/app/core/models/form-state.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import * as fromContext from 'src/app/core/state/context';
import * as fromContract from 'src/app/core/state/contract';
import { State } from 'src/app/core/state/core.state';
import * as fromDevis from 'src/app/core/state/devis';
import * as fromInfo from 'src/app/core/state/info';
import * as fromPayment from 'src/app/core/state/payment';
import * as fromService from 'src/app/core/state/service';
import * as fromTarif from 'src/app/core/state/tarification';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { CANCELATION_DELAY } from 'src/app/shared/constants/cancelation.constants';
import { CANAL_VENTE_C } from 'src/app/shared/constants/context.constants';
import { CONTEXT, MSAL_TOKEN } from 'src/app/shared/constants/localstorage.constants';
import {
  ELECTRONIC_SIGNATURE_PATH,
  E_SIGN_MSG_ADVISOR_PATH
} from 'src/app/shared/constants/route.constants';
import { getMetropolePhoneNumber } from 'src/app/shared/helpers/metropole-helper.service';
import { HeaderVM } from 'src/app/shared/models/components/headerVm';
import { QuestionVM } from 'src/app/shared/models/components/questionVm';
import { EcheancePrelevementNumber } from 'src/app/shared/models/enum/echeancePrelevementNumber.enum';
import { PaymentEnum } from 'src/app/shared/models/enum/payment.enum';
import { DateUtils } from 'src/app/shared/utils/dateUtils';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import * as fromPath from '../../../core/state/path';

interface Prelevement {
  text: string;
  value: string;
}

interface AccountOwner {
  value: boolean;
  text: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentComponent extends BaseComponent implements OnInit {
  headerStep!: HeaderVM;
  override question!: QuestionVM;
  stepB: boolean = false;
  displayModal: boolean = false;
  contractAlreadyExist: boolean = false;

  mensuel: boolean = false;
  trimestriel: boolean = false;
  annuel: boolean = false;

  minDate!: Date;
  maxDate!: Date;
  disabledDate: boolean = false;

  //Si conseiller ou client
  isAdvisor: boolean = false;
  promoMonthOffered: boolean = false;

  //Form
  echeancePrelevement!: EcheancePrelevement;
  echeancePrelevementValue: number = 1;
  dateStarting!: string;
  prelevements!: Prelevement[];
  selectedPrelevement!: Prelevement | undefined;
  valueIBAN!: string;

  accountOwners!: AccountOwner[];
  selectedAccountOwner: AccountOwner | undefined;

  paymentForm!: FormGroup;
  paymentEnum = PaymentEnum;

  isLoading: boolean = false;
  displayError: boolean = false;
  displayErrorIBAN: boolean = false;

  displayModalOwner: boolean = false;
  url!: string;

  displayErrorPhoneNumber: boolean = false;

  clientAdvisorFullName!: string;

  buttonTextTel!: string;
  validationMessage: string;

  get dateDemarrage() {
    return this.paymentForm.get('dateDemarrage');
  }

  get prelevement() {
    return this.paymentForm.get('prelevement');
  }

  get iban() {
    return this.paymentForm.get('iban');
  }

  get accountOwner() {
    return this.paymentForm.get('accountOwner');
  }

  get brand() {
    return environment.brand;
  }

  get phonePrefix() {
    return this.paymentForm.get('phoneGroup')?.get('phonePrefix');
  }

  get phone() {
    return this.paymentForm.get('phoneGroup')?.get('phone');
  }

  get isPhoneConsent() {
    return this.paymentForm.get('phoneGroup')?.get('isPhoneConsent');
  }

  constructor(
    store: Store<State>,
    resources: AppResource,
    tracker: MatomoTracker,
    public router: Router,
    private policiesService: PoliciesService,
    private ProductVersionService: ProductVersionService,
    private clientAdvisorService: ClientAdvisorService
  ) {
    super(store, resources, tracker);

    this.headerStep = new HeaderVM(
      this.brand === 'HM' ? this.resource.header.stepContract : this.resource.header.stepSample,
      3,
      this.resource.header.subStepPayment,
      8
    );
    this.question = new QuestionVM(this.resource.question.payment);

    this.prelevements = [
      { text: 'Le 5 du mois', value: '5' },
      { text: 'Le 10 du mois', value: '10' },
      { text: 'Le 15 du mois', value: '15' }
    ];

    this.accountOwners = [
      { text: this.resource.payment.accountOwnerYes, value: true },
      { text: this.resource.payment.accountOwnerNo, value: false }
    ];

    this.validationMessage = this.resource.payment.textModal1;
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.initForm();
    this.loadStateData();
    let currentDate: Date = new Date();
    currentDate.setDate(currentDate.getDate() + 15);
    this.clientAdvisorFullName = await this.clientAdvisorService.getClientAdvisorFullName();
  }

  private initForm() {
    this.paymentForm = new FormGroup(
      {
        dateDemarrage: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        prelevement: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        iban: new FormControl<string | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        }),
        accountOwner: new FormControl<boolean | null>(null, {
          validators: [Validators.required],
          nonNullable: true
        })
      },
      {
        updateOn: 'change'
      }
    );
  }

  private async loadStateData() {
    let path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    this.setButtonTelText(path);

    this.store.select(fromContext.selectContext).subscribe(res => {
      if (res.contextData != null)
        if (res.contextData?.modeConnexion != 'COB') this.isAdvisor = true;
    });

    this.store.select(fromPayment.selectPayment).subscribe(res => {
      switch (res.payment.EcheancePrelevement) {
        case EcheancePrelevement.Mensuel:
          this.mensuel = true;
          break;
        case EcheancePrelevement.Trimestriel:
          this.trimestriel = true;
          break;
        case EcheancePrelevement.Annuel:
          this.annuel = true;
          break;
        default:
          this.mensuel = true;
          break;
      }

      this.selectedPrelevement = this.prelevements.find(obj => {
        if (res.payment != null) return obj.value === res.payment.JourPrelevement;
        else return undefined;
      });

      this.dateStarting =
        DateUtils.IsoToDayMonthYear(res.payment.DateDemarrage) ?? this.dateStarting;

      this.valueIBAN = res.payment.IBAN;
      this.selectedAccountOwner = this.accountOwners.find(obj => {
        if (res.payment != null) return obj.value === res.payment.TitulaireCompte;
        else return undefined;
      });
    });

    this.store.select(fromService.selectService).subscribe(async res => {
      if (!this.dateStarting) {
        if (res.saisieChoixServices.ResAncAss) {
          if (res.saisieChoixServices.ResiliationAncienneAssurance?.DemenagementPlusUnAn) {
            let date = new Date(Date.now());
            date = new Date(date.setDate(date.getDate() + CANCELATION_DELAY));
            this.dateStarting = DateUtils.DateToDayMonthYear(date) ?? this.dateStarting;
            this.disabledDate = true;
          } else if (
            res.saisieChoixServices.ResiliationAncienneAssurance?.DemenagementPlusUnAn == false
          ) {
            let date = new Date(
              res.saisieChoixServices.ResiliationAncienneAssurance.MoisDemarrageContrat
            );
            this.dateStarting =
              DateUtils.DateToDayMonthYear(new Date(date.setMonth(date.getMonth() + 12))) ??
              this.dateStarting; // + 12 mois
            this.disabledDate = true;
          }
        } else {
          let date = new Date(Date.now());
          this.minDate = new Date(date.setDate(date.getDate() + 1));
          let referenceDevis = await lastValueFrom(
            this.store.select(fromDevis.selectDevisState).pipe(
              take(1),
              map(d => d.NumeroDevis)
            )
          );
          this.ProductVersionService.getByProductVersion(referenceDevis).subscribe(data => {
            this.maxDate = new Date(date.setDate(date.getDate() + data.policyEffectiveMaxDays!));
          });
        }
      }
    });

    // Retrieve promotion informations
    const promotion = await lastValueFrom(
      this.store.select(fromTarif.selectTarificationPromotion).pipe(take(1))
    );

    this.promoMonthOffered = promotion?.TypePromo === TypePromo.MoisOfferts;
  }

  updateClickButton(value: string) {
    switch (value) {
      case PaymentEnum.Mensuel:
        if (!this.mensuel) {
          this.mensuel = !this.mensuel;
          this.trimestriel = false;
          this.annuel = false;
          this.echeancePrelevement = EcheancePrelevement.Mensuel;
          this.echeancePrelevementValue = EcheancePrelevementNumber.Mensuel;
          this.paymentForm.controls['prelevement'].addValidators(Validators.required);
          this.paymentForm.controls['prelevement'].updateValueAndValidity();
        }
        break;
      case PaymentEnum.Trimestriel:
        if (!this.trimestriel) {
          this.mensuel = false;
          this.trimestriel = !this.trimestriel;
          this.annuel = false;
          this.echeancePrelevement = EcheancePrelevement.Trimestriel;
          this.echeancePrelevementValue = EcheancePrelevementNumber.Trimestriel;
          this.paymentForm.controls['prelevement'].addValidators(Validators.required);
          this.paymentForm.controls['prelevement'].updateValueAndValidity();
        }
        break;
      case PaymentEnum.Annuel:
        if (!this.annuel) {
          this.mensuel = false;
          this.trimestriel = false;
          this.annuel = !this.annuel;
          this.echeancePrelevement = EcheancePrelevement.Annuel;
          this.echeancePrelevementValue = EcheancePrelevementNumber.Annuel;
          this.paymentForm.controls['prelevement'].removeValidators(Validators.required);
          this.paymentForm.controls['prelevement'].updateValueAndValidity();
        }
        break;
    }
  }

  public async showModalDialog() {
    // Retrieve service
    const serviceState = await lastValueFrom(
      this.store.select(fromService.selectService).pipe(take(1))
    );

    this.validationMessage = serviceState.saisieChoixServices.ResAncAss
      ? this.resource.payment.textModal1_CancellationOption
      : this.resource.payment.textModal1;

    if (this.selectedAccountOwner?.value === false) this.displayModalOwner = true;
    else this.displayModal = true;
  }

  public async validPayment() {
    this.isLoading = true;

    let dateParts = this.dateDemarrage?.value.split('/');
    let formattedStartDate = dateParts
      ? `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      : undefined;

    const saisiePaiement: SaisiePaiement = {
      EcheancePrelevement: this.echeancePrelevement,
      DateDemarrage: formattedStartDate,
      JourPrelevement: this.selectedPrelevement?.value,
      IBAN: this.iban?.value,
      PrefixeTel: this.phonePrefix?.value['prefix'],
      Telephone: this.phone?.value,
      TitulaireCompte: this.selectedAccountOwner?.value,
      ConsentementTelephonique: this.paymentForm.get('phoneGroup')?.get('isPhoneConsent')?.value
    };

    this.store.dispatch(
      fromPayment.patchPayment({
        payload: saisiePaiement
      })
    );

    this.store.dispatch(
      fromPayment.changeValidationStatus({
        isValid: true
      })
    );

    // Retrieve devis Ref
    let devisRef = await lastValueFrom(
      this.store.select(fromDevis.selectDevisState).pipe(
        take(1),
        map(d => d.NumeroDevis)
      )
    );

    if (!devisRef) {
      const contextData = await lastValueFrom(
        this.store.select(fromContext.selectContextData).pipe(take(1))
      );
      devisRef = contextData?.referenceDevis!;
    }

    //Retrieve devis Id
    let devisId = await lastValueFrom(
      this.store.select(fromDevis.selectDevisState).pipe(
        take(1),
        map(d => d.DevisId)
      )
    );

    // Retrieve service
    const serviceState = await lastValueFrom(
      this.store.select(fromService.selectService).pipe(take(1))
    );

    //Récupération des données pour la création du contrat
    let localStorageContext = localStorage.getItem(CONTEXT);
    let token;
    if (localStorageContext) {
      let contextObj = JSON.parse(localStorageContext);
      token = contextObj.token;
    }
    const bodyPolicy: Policies = {
      contextId: Guid.parse(token)['value'],
      quotationId: devisId ?? Guid.create()['value'],
      startDate: formattedStartDate!,
      phonePrefix: this.phonePrefix?.value.prefix,
      phoneNumber: this.phone?.value,
      paymentInfo: {
        iban: this.iban?.value,
        hasIbanOwnership: this.selectedAccountOwner?.value ?? false,
        fractionationType: this.echeancePrelevementValue,
        debitDay: Number(this.selectedPrelevement?.value)
      },
      userConsents: {
        userCommercialConsent: serviceState.saisieChoixServices.OppositionMarketing,
        phoneConsent: this.isPhoneConsent?.value
      },
      operationalService: {
        sendCertificateToLessor: serviceState.saisieChoixServices.EnvAttB,
        cancelCurrentInsurance: serviceState.saisieChoixServices.ResAncAss,
        lessorData: {
          lessorType: serviceState.saisieChoixServices.EnvoiAttestationBailleur?.TypeBailleur ?? '',
          name:
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurPrive?.NomBailleur ??
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial
              ?.NomBailleur ??
            String.empty,
          email:
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurPrive?.Email ??
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.Email ??
            String.empty,
          houseIsOccuped:
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial
              ?.LogementOccupe !== undefined
              ? serviceState.saisieChoixServices.EnvoiAttestationBailleur.BailleurSocial
                  .LogementOccupe
              : null,
          ref1:
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.Reference1 ??
            String.empty,
          ref2:
            serviceState.saisieChoixServices.EnvoiAttestationBailleur?.BailleurSocial?.Reference2 ??
            String.empty
        },
        insuranceData: {
          name: serviceState.saisieChoixServices.ResiliationAncienneAssurance?.NomAssureur ?? '',
          address:
            serviceState.saisieChoixServices.ResiliationAncienneAssurance?.AddressAssureur ?? '',
          zipCode:
            serviceState.saisieChoixServices.ResiliationAncienneAssurance?.CodePostalAssureur ?? '',
          city: serviceState.saisieChoixServices.ResiliationAncienneAssurance?.VilleAssureur ?? '',
          contractNumber:
            serviceState.saisieChoixServices.ResiliationAncienneAssurance?.NumeroContrat ?? ''
        }
      }
    };
    const stateContextData = await lastValueFrom(
      this.store.select(fromContext.selectContextData).pipe(take(1))
    );
    if (this.phonePrefix?.value.prefix !== null && this.phone?.value !== null) {
      this.policiesService.createPolicy(bodyPolicy).subscribe({
        next: (resPolicy: any) => {
          this.store.dispatch(
            fromContract.patchContract({
              payload: {
                NumeroContrat: resPolicy['id']
              }
            })
          );
          if (
            stateContextData?.canalVente !== CANAL_VENTE_C &&
            sessionStorage.getItem(MSAL_TOKEN) === null
          ) {
            this.router.navigate([ELECTRONIC_SIGNATURE_PATH]);
          } else {
            this.router.navigate([E_SIGN_MSG_ADVISOR_PATH]);
          }
          this.updateStepper();
        },
        error: resContract => {
          this.isLoading = false;
          this.displayModal = false;
          if (resContract.error?.status === 400) {
            this.contractAlreadyExist = true;
          } else if (resContract.error?.status === 422) {
            this.displayErrorIBAN = true;
          } else {
            this.displayError = true;
          }
        },
        complete: () => {
          this.isLoading = false;
          this.displayModal = false;
        }
      });
    } else {
      this.displayErrorPhoneNumber = true;
    }
  }

  public cancelPayment() {
    this.displayModal = false;
  }

  async updateStepper() {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.sampling
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.payment
    );

    if (subtitleS) {
      subtitleS.isValid = true;
      subtitleS.isActive = true;
    }

    let titleN = tmpStepper.contract.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.signature
    );

    if (titleN) titleN.isActive = true;

    let subtitleNS = titleN?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.electronicsignature
    );

    if (subtitleNS) {
      subtitleNS.isActive = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  onCancel() {
    this.displayModalOwner = false;
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  getClass(value: boolean) {
    return value ? 'activeP-ES' : 'inactiveP-ES';
  }

  addPhoneControls(phoneControls: FormGroup) {
    this.paymentForm.addControl('phoneGroup', phoneControls);
    this.paymentForm.updateValueAndValidity();
  }

  onDateChange(date: string) {
    this.dateStarting = date;
  }

  setButtonTelText(path: string) {
    const metropolePaths = [
      PathType.HOUSING_PARIS,
      PathType.HOUSING_TOURCOING,
      PathType.HOUSING_LILLE
    ];

    if (path === PathType.HOUSING_SOLIDARITY) {
      this.buttonTextTel = this.resource.webcallback.phoneAHS;
    } else if (metropolePaths.includes(path as PathType)) {
      this.buttonTextTel = String.format(
        this.resource.webcallback.phoneAMetropole,
        getMetropolePhoneNumber(path)
      );
    } else {
      this.buttonTextTel = this.resource.webcallback.phoneAHC;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { NatureResidence, QualiteOccupant } from 'src/app/core/models/form-state.model';
import { Question } from 'src/app/core/models/question.model';
import { cloneResumeStep } from 'src/app/core/models/resume-step.model';
import { Step } from 'src/app/core/models/step.model';
import { Stepper } from 'src/app/core/models/stepper.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component.component';
import { MatomoConstants } from 'src/app/shared/constants/matomo.constants';
import * as fromRoot from '../../../core/state/core.state';
import * as fromHousing from '../../../core/state/housing';
import * as fromInfo from '../../../core/state/info';
import * as fromPath from '../../../core/state/path';

@Component({
  selector: 'app-primary-secondary',
  templateUrl: './primary-secondary.component.html',
  styleUrls: ['./primary-secondary.component.scss']
})
export class PrimarySecondaryComponent extends BaseComponent implements OnInit {
  primaryMouseOvered = false;
  secondaryMouseOvered = false;

  primarySelected = false;
  secondarySelected = false;

  override step: Step = {
    step: this.resource.header.stepHousing,
    stepNumber: 1,
    subStep: this.resource.header.subStepPrimarySecondary,
    subStepNumber: 4
  };

  override question: Question = {
    libelle: this.resource.question.primarySecondary,
    popupInfo: undefined
  };
  housingData!: fromHousing.HousingState;
  path!: PathType;

  public override currentPage = MatomoConstants.RESIDENCE;

  constructor(
    store: Store<fromRoot.State>,
    resources: AppResource,
    tracker: MatomoTracker,
    private router: Router
  ) {
    super(store, resources, tracker);
  }

  override async ngOnInit() {
    super.ngOnInit();
    this.updateResumeStepVisibility(true);
    this.loadStateData();
  }

  private async loadStateData() {
    this.housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));

    switch (this.housingData.SaisieHabitation.NatureResidence) {
      case NatureResidence.Principale:
        this.primarySelected = true;
        break;
      case NatureResidence.Secondaire:
        this.secondarySelected = true;
        break;
      default:
        break;
    }
  }

  async onPrimarySelected() {
    this.primarySelected = !this.primarySelected;
    if (this.secondarySelected) this.secondarySelected = false;

    this.store.dispatch(
      fromHousing.patchHousingNature({
        payload: { NatureResidence: NatureResidence.Principale }
      })
    );

    var res = await lastValueFrom(
      this.store.select(fromHousing.selectHousingOccupantType).pipe(take(1))
    )!;

    let previousPath = await lastValueFrom(
      this.store.select(fromPath.selectPreviousPathType).pipe(take(1))
    );

    if (res == QualiteOccupant.Locataire && previousPath && previousPath != PathType.HOUSING) {
      this.store.dispatch(
        fromPath.updateType({
          pathType: previousPath
        })
      );
    }

    this.nextStep();
  }

  async onSecondarySelected() {
    this.secondarySelected = !this.secondarySelected;
    if (this.primarySelected) this.primarySelected = false;

    this.store.dispatch(
      fromHousing.patchHousingNature({
        payload: { NatureResidence: NatureResidence.Secondaire }
      })
    );

    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    if (this.secondarySelected && this.path == PathType.HOUSING) {
      this.tracker.trackEvent(
        MatomoConstants.AHP_TO_AHC,
        MatomoConstants.RESIDENCE,
        MatomoConstants.RESIDENCE_AHC
      );
    }
    if (this.path != PathType.HOUSING) {
      this.store.dispatch(
        fromPath.updatePreviousType({
          previousPathType: this.path
        })
      );
    }
    this.store.dispatch(
      fromPath.updateType({
        pathType: PathType.HOUSING
      })
    );
    this.nextStep();
  }

  async nextStep() {
    this.path = await lastValueFrom(this.store.select(fromPath.selectPathType).pipe(take(1)));
    this.housingData = await lastValueFrom(
      this.store.select(fromHousing.selectHousing).pipe(take(1))
    );
    await this.updateResumeStep();

    const isMetropole =
      this.path === PathType.HOUSING_PARIS ||
      this.path === PathType.HOUSING_TOURCOING ||
      this.path === PathType.HOUSING_LILLE;
    const isLocatairePrincipale =
      this.housingData.SaisieHabitation.QualiteOccupant === QualiteOccupant.Locataire &&
      this.housingData.SaisieHabitation.NatureResidence === NatureResidence.Principale;

    if (isMetropole && isLocatairePrincipale) {
      await this.updateStepper(true);
      this.router.navigate(['/preferentialrate']);
    } else {
      await this.updateStepper(false);
      this.router.navigate(['/numberpieces']);
    }
  }

  async updateStepper(preferentialrate: boolean) {
    let stepper = await lastValueFrom(this.store.select(fromInfo.selectStepper).pipe(take(1)));
    var tmpStepper = JSON.parse(JSON.stringify(stepper)) as Stepper;

    let title = tmpStepper.devis.titles.find(
      (x: { libelle: string }) => x.libelle == this.resource.stepper.lodging
    );

    let subtitleS = title?.subtitles.find(
      (x: { url: string }) => x.url == this.resource.stepper.primarysecondary
    );

    if (subtitleS) subtitleS.isValid = true;

    let subtitleN;
    if (preferentialrate) {
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.preferentialrate
      );
    } else {
      subtitleN = title?.subtitles.find(
        (x: { url: string }) => x.url == this.resource.stepper.numberpieces
      );
    }

    if (subtitleN) {
      subtitleN.isActive = true;
      subtitleN.isVisible = true;
    }

    this.store.dispatch(fromInfo.updateStepper({ payload: tmpStepper }));
  }

  async updateResumeStep() {
    const resumeStep = await lastValueFrom(
      this.store.select(fromInfo.selectCurrentResumeStep).pipe(take(1))
    );

    const tmpResumeStep = cloneResumeStep(resumeStep);

    const stepResumeStep = tmpResumeStep.steps?.find(
      x => x.id == this.resource.resumestep.primarysecondary
    );
    let libelle = '';

    if (this.primarySelected) libelle = this.resource.resumestep.primary;
    else if (this.secondarySelected) libelle = this.resource.resumestep.secondary;

    if (stepResumeStep && libelle != '') {
      stepResumeStep.step = libelle;
      stepResumeStep.isVisible = true;
    } else {
      tmpResumeStep.steps?.push({
        id: this.resource.resumestep.primarysecondary,
        step: libelle,
        url: this.resource.resumestep.primarysecondary,
        isVisible: true
      });
    }
    this.store.dispatch(fromInfo.updateResumeStep({ payload: tmpResumeStep }));
  }

  setClassActive(value: boolean): string {
    return value ? 'active' : 'inactive';
  }
}

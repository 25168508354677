import { Store } from '@ngrx/store';
import { MatomoTracker } from 'ngx-matomo-client';
import { VyVSharedProductCode } from 'src/app/api/models/vyVSharedProductCode';
import { PathType } from 'src/app/core/enums/path-type.enum';
import * as fromPath from 'src/app/core/state/path';
import { lillePostalCode } from 'src/app/views/general-information/address/address.lillePostalCode';
import { tourcoingPostalCode } from 'src/app/views/general-information/address/address.tourcoingPostalCode';
import { MatomoConstants } from '../constants/matomo.constants';
import { PackageCode } from '../models/enum/packageCode.enum';

export interface ReferenceScale {
  Id: number;
  NombrePersonnes: number;
  Montant: number;
}

const metropoleMapping: {
  [key: string]: {
    code: PackageCode;
    pathType: PathType;
    name: string;
    cityName: string;
    residentName: string;
    phoneNumber: string;
    classNameOne: string;
    classNameTwo: string;
    classNameThree: string;
    capPictureName: string;
    bareme: ReferenceScale[];
  };
} = {
  [VyVSharedProductCode.HP]: {
    code: PackageCode.PARISIENNE,
    pathType: PathType.HOUSING_PARIS,
    name: 'Parisienne',
    cityName: 'Paris',
    residentName: 'parisien',
    phoneNumber: '01 23 45 67 89',
    classNameOne: 'panelPLogo-OC',
    classNameTwo: 'panelCPLogo-R',
    classNameThree: 'panelPLogo-QC',
    capPictureName: 'plafondsHp',
    bareme: [
      { Id: 0, NombrePersonnes: 1, Montant: 11817 },
      { Id: 1, NombrePersonnes: 1, Montant: 33860 },
      { Id: 2, NombrePersonnes: 2, Montant: 50605 },
      { Id: 3, NombrePersonnes: 3, Montant: 66337 },
      { Id: 4, NombrePersonnes: 4, Montant: 79203 },
      { Id: 5, NombrePersonnes: 5, Montant: 94234 },
      { Id: 6, NombrePersonnes: 6, Montant: 106038 }
    ]
  },
  [VyVSharedProductCode.HT]: {
    code: PackageCode.TOURQUENNOISE,
    pathType: PathType.HOUSING_TOURCOING,
    name: 'Tourquennoise',
    cityName: 'Tourcoing',
    residentName: 'tourquennois',
    phoneNumber: '02 34 56 78 90',
    classNameOne: 'panelTLogo-OC',
    classNameTwo: 'panelCTLogo-R',
    classNameThree: 'panelTLogo-QC',
    capPictureName: 'plafondsHt',
    bareme: [
      { Id: 0, NombrePersonnes: 1, Montant: 8440 },
      { Id: 1, NombrePersonnes: 1, Montant: 29435 },
      { Id: 2, NombrePersonnes: 2, Montant: 39309 },
      { Id: 3, NombrePersonnes: 3, Montant: 42271 },
      { Id: 4, NombrePersonnes: 4, Montant: 57069 },
      { Id: 5, NombrePersonnes: 5, Montant: 67133 },
      { Id: 6, NombrePersonnes: 6, Montant: 75660 }
    ]
  },
  [VyVSharedProductCode.HL]: {
    code: PackageCode.LILLOISE,
    pathType: PathType.HOUSING_LILLE,
    name: 'Lilloise',
    cityName: 'Lille',
    residentName: 'lillois',
    phoneNumber: '02 34 56 78 90',
    classNameOne: 'panelLLogo-OC',
    classNameTwo: 'panelCLLogo-R',
    classNameThree: 'panelLLogo-QC',
    capPictureName: 'plafondsHl',
    bareme: [
      { Id: 0, NombrePersonnes: 1, Montant: 8440 },
      { Id: 1, NombrePersonnes: 1, Montant: 29435 },
      { Id: 2, NombrePersonnes: 2, Montant: 39309 },
      { Id: 3, NombrePersonnes: 3, Montant: 42271 },
      { Id: 4, NombrePersonnes: 4, Montant: 57069 },
      { Id: 5, NombrePersonnes: 5, Montant: 67133 },
      { Id: 6, NombrePersonnes: 6, Montant: 75660 }
    ]
  }
};

export function getMetropoleClassOne(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.classNameOne;
}

export function getMetropoleClassTwo(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.classNameTwo;
}

export function getMetropoleClassThree(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.classNameThree;
}

export function getMetropolePackageCode(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.code;
}

export function getMetropoleBaseName(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.name;
}

export function getMetropolePhoneNumber(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.phoneNumber;
}

export function getMetropoleResidentName(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.residentName;
}

export function getMetropoleCityName(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.cityName;
}

export function getMetropoleCapPictureName(idProduit: string): string | undefined {
  return metropoleMapping[idProduit]?.capPictureName;
}

export function getMetropoleBareme(
  idProduit: string
): { Id: number; NombrePersonnes: number; Montant: number }[] | undefined {
  return metropoleMapping[idProduit].bareme;
}

export function metropolePathSwitch(postalCode: string, store: Store, tracker: MatomoTracker) {
  if (postalCode?.startsWith('75')) {
    store.dispatch(fromPath.updateType({ pathType: PathType.HOUSING_PARIS }));
  } else if (lillePostalCode.includes(postalCode)) {
    store.dispatch(fromPath.updateType({ pathType: PathType.HOUSING_LILLE }));
  } else if (tourcoingPostalCode.includes(postalCode)) {
    store.dispatch(fromPath.updateType({ pathType: PathType.HOUSING_TOURCOING }));
  } else {
    store.dispatch(fromPath.updateType({ pathType: PathType.HOUSING }));
    tracker.trackEvent(MatomoConstants.ADDRESS, MatomoConstants.AHP_TO_AHC);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, take } from 'rxjs';
import { AppResource } from 'src/app/app.resource';
import { PathType } from 'src/app/core/enums/path-type.enum';
import { State } from 'src/app/core/state/core.state';
import * as fromPath from 'src/app/core/state/path';
import { Resource } from 'src/app/resources/resource';
import { environment } from 'src/environments/environment';
import { String } from 'typescript-string-operations';
import { getMetropolePhoneNumber } from '../../helpers/metropole-helper.service';

@Component({
  selector: 'app-web-callback-popup',
  templateUrl: './web-callback-popup.component.html'
})
export class WebCallbackPopupComponent implements OnInit {
  @Input() show: boolean = false;

  @Output() cancel = new EventEmitter<boolean>();

  public resource!: Resource;
  url!: string;
  path!: PathType;

  phoneNumber!: string;

  get brand() {
    return environment.brand;
  }

  constructor(private resources: AppResource, private store: Store<State>) {
    this.resource = resources['resource'];
  }

  async ngOnInit() {
    this.path = await (
      await lastValueFrom(this.store.select(fromPath.selectPath).pipe(take(1)))!
    ).pathType;

    this.phoneNumber = this.getPhoneNumber(this.path);
  }

  onCancel() {
    this.cancel.emit();
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  private getPhoneNumber(pathType: PathType) {
    switch (pathType) {
      case PathType.HOUSING_SOLIDARITY:
        return this.resource.webcallback['phoneAHS'];
      case PathType.HOUSING_PARIS:
      case PathType.HOUSING_TOURCOING:
      case PathType.HOUSING_LILLE:
        return String.format(
          this.resource.webcallback['phoneAMetropole'],
          getMetropolePhoneNumber(pathType)
        );
      case PathType.HOUSING:
      default:
        return this.resource.webcallback['phoneAHC'];
    }
  }
}
